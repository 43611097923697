import {
  Button,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  makeStyles,
  styled,
} from "@material-ui/core"
import { Visibility } from "@material-ui/icons"
import ClearIcon from "@material-ui/icons/Clear"
import EditIcon from "@material-ui/icons/Edit"
import SearchIcon from "@material-ui/icons/SearchOutlined"
import html2pdf from "html2pdf.js"
import { filter } from "lodash"
import { useEffect, useState } from "react"
import ReactDOMServer from "react-dom/server"
import { useHistory } from "react-router-dom"
import Api from "../../Service/Api"
import { baseurl } from "../../Service/httpCommon"
import GSTVoucherPDF from "../../assets/Icons/voucherIcons/GST.png"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import GstInvoiceVoucherPDF from "../../components/pdfVoucherComponents/GSTInvoiceVoucherPDF.component"
import { twnButtonStyles } from "../../utils/townoStyle"
import InvoiceListMobileUI from "./gstInvoiceListMobileUI"

{
  /*Function for sorting records starts*/
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_invoicedata) =>
        _invoicedata.clientName.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _invoicedata.hotelName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  }
  return stabilizedThis.map((el) => el[0])
}
{
  /*Function for sorting records ends*/
}

const GstInvoiceList = () => {
  const classes = useStyles()
  const history = useHistory()
  const [invoiceListData, setInvoiceListData] = useState([])
  var uniqueId = localStorage.getItem("unique_id")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value)
    setPage(0)
  }

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const response = await Api.get(`${baseurl}gst/${uniqueId}`)
        console.log(response.data)
        setInvoiceListData(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    console.log("Hello")

    fetchListData()
  }, [])

  const tableHeadValues = [
    "Invoice No.",
    "Client Name",
    "Hotel Name",
    "Created Date",
    // "Booking Date",
    "Taxable Amount",
    "Total Tax",
    "Amount Inc. Gst",
    "Action",
  ]

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    background: "#F46D25",
    height: "40px",
  }))
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    background: "#F46D25",
    color: "#fff",
    fontSize: "12px",
    padding: "5px",
    lineHeight: "1rem",
    minWidth: "100px",
  }))

  const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
    padding: "5px",
    fontSize: "14px",
    lineHeight: "15px",
  }))
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [textToSearch, setTextToSearch] = useState("")

  const filteredInvoiceDataList = applySortFilter(
    invoiceListData,
    getComparator(order, orderBy),
    textToSearch
  )

  const [isDownloading, setIsDownloading] = useState(false)

  const DownloadInvoice = (data) => {
    setIsDownloading(true)
    console.log(data)
    let data1 = data.invoiceNo
    const link = document.createElement("a")
    link.target = "_blank"
    link.download = `${data1}.pdf`
    Api.get(`gstpdf/${uniqueId}/${data.invoiceNo}`, {
      responseType: "blob",
    })
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "pdf" }))
        link.click()
        setIsDownloading(false)
      })
      .catch((error) => {
        if (error) {
          setIsDownloading(false)
          alert(error)
        }
      })
  }

  const createGSTVoucherPDF = async (invoiceId, fileName) => {
    setIsDownloading(true)
    const role = localStorage.getItem("role")
    try {
      let voucherUrl =
        role === "Agent Admin"
          ? "gstDetailsForAgencyVoucher"
          : "gstDetailsForVoucher"

      const { data } = await Api.get(`${voucherUrl}/${uniqueId}/${invoiceId}`)

      const totalRate = await data.gstBillingInformation.reduce(
        (prev, curr) => (prev += parseFloat(curr.rate)),
        0
      )

      const totalQuantity = await data.gstBillingInformation.reduce(
        (prev, curr) => (prev += parseFloat(curr.quantity)),
        0
      )

      const totalIgst =
        (await data.gstBilledBy.state.toLowerCase()) !==
        data.gstBilledTo.clientState.toLowerCase()
          ? data.gstBillingInformation.reduce(
              (prev, curr) => (prev += parseFloat(curr.igst)),
              0
            )
          : "-"

      const totalCgst =
        (await data.gstBilledBy.state.toLowerCase()) ===
        data.gstBilledTo.clientState.toLowerCase()
          ? data.gstBillingInformation.reduce(
              (prev, curr) => (prev += parseFloat(curr.cgst)),
              0
            )
          : "-"

      const totalSgst =
        (await data.gstBilledBy.state.toLowerCase()) ===
        data.gstBilledTo.clientState.toLowerCase()
          ? data.gstBillingInformation.reduce(
              (prev, curr) => (prev += parseFloat(curr.sgst)),
              0
            )
          : "-"
      let pdfComponent = (
        <GstInvoiceVoucherPDF
          voucherDetails={data}
          totalRate={totalRate}
          totalQuantity={totalQuantity}
          totalIgst={totalIgst}
          totalCgst={totalCgst}
          totalSgst={totalSgst}
        />
      )
      let jsxString = await ReactDOMServer.renderToStaticMarkup(pdfComponent)

      const pdfOptions = {
        // margin: 10,
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 3,
          useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      }

      const element = document.createElement("div")
      element.innerHTML = jsxString

      await html2pdf().from(element).set(pdfOptions).save()
      setIsDownloading(false)
    } catch (error) {
      console.error("Error creating PDF:", error)
      setIsDownloading(false)
      alert(error)
    }
  }

  return (
    <div style={twnButtonStyles.allPages}>
      {width <= 768 ? (
        <InvoiceListMobileUI
          data={filteredInvoiceDataList}
          textToSearch={textToSearch}
          setTextToSearch={setTextToSearch}
        />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h1 style={twnButtonStyles.xlFonts}>Invoice List</h1>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  marginLeft: "-50px",
                }}
              >
                <Slide direction="left" in={search} mountOnEnter unmountOnExit>
                  <TextField
                    type="search"
                    variant="outlined"
                    value={textToSearch}
                    onChange={(e) => setTextToSearch(e.target.value)}
                    placeholder="Search by Hotel / Client"
                    InputProps={{
                      style: {
                        height: "32px",
                        marginLeft: "-205px",
                        position: "absolute",
                        width: "230px",
                        borderRadius: "5px 10px 10px 5px",
                      },
                    }}
                  />
                </Slide>
                <IconButton
                  onClick={() => {
                    setSearch(!search)
                    search === false && setTextToSearch("")
                  }}
                  style={{
                    background: "#F46D25",
                    color: "white",
                    marginRight: "10px",
                    width: "32px",
                    height: "32px",
                    padding: "10px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                >
                  {search ? <ClearIcon /> : <SearchIcon />}
                </IconButton>
              </div>

              <Button
                style={{ height: "40px", ...twnButtonStyles.orangeBtn }}
                onClick={() => history.push("/konnect/generateInvoice")}
              >
                Create New Invoice
              </Button>
            </div>
          </div>
          <TableContainer component={Paper} style={{ marginTop: "0.5%" }}>
            <Table stickyHeader size="small">
              <TableHead>
                <StyledTableRow>
                  {tableHeadValues.map((value) => (
                    <StyledTableCell
                      key={value}
                      style={{
                        color: "white",

                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      {value}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {invoiceListData &&
                  filteredInvoiceDataList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow key={row.id} style={{ height: "40px" }}>
                          <StyledTableCellInside
                            style={{
                              textTransform: "capitalize",
                              color: "#f46d25",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            align="center"
                          >
                            <span
                              onClick={() =>
                                history.push(
                                  `/konnect/gstInvoiceVoucher/${row.invoiceNo}`
                                )
                              }
                            >
                              {" "}
                              {row.invoiceNo}
                            </span>
                          </StyledTableCellInside>
                          <StyledTableCellInside align="center">
                            {row.clientName}
                          </StyledTableCellInside>
                          <StyledTableCellInside align="center">
                            {row.hotelName}
                          </StyledTableCellInside>
                          <StyledTableCellInside align="center">
                            {row.createdAt
                              .split(" ")[0]
                              .split("-")
                              .reverse()
                              .join("-")}
                          </StyledTableCellInside>

                          <StyledTableCellInside align="center">
                            {row.totalTaxableAmount}
                          </StyledTableCellInside>
                          <StyledTableCellInside align="center">
                            {row.totalTax}
                          </StyledTableCellInside>
                          <StyledTableCellInside align="center">
                            {row.totalAmountWithGst}
                          </StyledTableCellInside>
                          <StyledTableCellInside
                            align="center"
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="edit"
                                style={{ padding: "0px", color: "#F46D25" }}
                                onClick={() =>
                                  history.push(
                                    `/konnect/generateInvoice/${row.invoiceNo}`
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <IconButton
                              style={{ padding: "0px", color: "#F46D25" }}
                              title="view"
                              aria-label="view"
                              onClick={() =>
                                history.push(
                                  `/konnect/viewGstInvoice/${row.invoiceNo}`
                                )
                              }
                            >
                              <Visibility />
                            </IconButton>
                            {/* <IconButton
                              style={{ padding: "0px", color: "#F46D25" }}
                              aria-label="Invoice Voucher"
                              title="Download Invoice"
                              onClick={() => DownloadInvoice(row)}
                            >
                              <GSTInvoiceLogo
                                style={{
                                  height: "25px",
                                  width: "100%",
                                }}
                              />
                            </IconButton> */}
                            <IconButton
                              style={{ padding: "0" }}
                              title="Download GST Voucher"
                              onClick={() =>
                                createGSTVoucherPDF(
                                  row.invoiceNo,
                                  `${row.invoiceNo}GST`
                                )
                              }
                            >
                              <img
                                src={GSTVoucherPDF}
                                style={{
                                  height: "20px",
                                  width: "auto",
                                  padding: "0",
                                }}
                              />
                            </IconButton>
                          </StyledTableCellInside>
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredInvoiceDataList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <ScreenLoader open={isDownloading} />
        </>
      )}
    </div>
  )
}

export default GstInvoiceList

const useStyles = makeStyles(() => ({
  root: {
    // padding: "100px 0px",
    // margin: "0px 30px",
    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      margin: "0px 10px",
    },
  },

  table: {
    backgroundColor: "#f46d25",
    color: "white",
  },

  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },

  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    color: "#f46d25",
    margin: "0px",
  },
  titles: {
    padding: "10px",
    fontSize: "28px",
    fontWeight: "lighter",
    color: "#fff",
    background: "#f46d25",
    margin: "20px 10px 10px 0px",
    borderRadius: "8px 8px 0px 0px",
  },
  formControl: {
    minWidth: "60px",
    height: "40px",
    background: "white",
  },
}))

const value = {
  invoiceNo: "",
  clientname: "",
  hotelName: "",
  createdDate: "",
  bookingDate: "",
  taxableAmount: "",
  totaltax: "",
  totalAmountWithGst: "",
}
