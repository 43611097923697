import React, {
  createContext,
  lazy,
  useContext,
  useEffect,
  useRef,
} from "react"
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min"
import LandingPage from "../InnerCircle_Konnect/Pages/LandingPage/LandingPage"
import AboutUs from "./Pages/AboutUs/AboutUs.page"
import ContactPage from "./Pages/Contact/Contact.page"
import Destination from "./Pages/Destination/Destination.page"
import Home from "./Pages/Home/Home.page"
import Hotels from "./Pages/Hotels/Hotels.page"
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy.component"
import RefundAndCancellationPolicy from "./Pages/RefundAndCancellationPolicy/RefundAndCancellationPolicy.page"
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions.page"
import "./RoutesB2C.css"
import ContactModal from "./components/ContactModal/ContactModal.component"
import Footer from "./components/Footer/Footer.component"
import Header from "./components/Header/header.component"
import Navbar from "./components/Navbar/Navbar.component"
import { ContactModalContext } from "./context/contactModal.context"
import { SideNavbarContext } from "./context/sideNavBarContext"

const B2CHomepage = lazy(() => import("./Pages/Home/Home.page"))

export const BackgroundContext = createContext(null)

const RoutesB2C = () => {
  const { openSideNavbar, setOpenSideNavbar } = useContext(SideNavbarContext)

  const { open, onClose } = useContext(ContactModalContext)

  const { url } = useRouteMatch()

  const { pathname } = useLocation()

  const mainContainerRef = useRef()
  const watiRef = useRef()

  useEffect(() => {
    const pageContainer =
      mainContainerRef.current.querySelector("#page-container")
    const children = pageContainer.querySelectorAll("section")
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let x = 80 * Number(window.scrollY / 10000)
          mainContainerRef.current.style.setProperty("--x", x + "%")
        }
      })
    })

    children.forEach((child) => observer.observe(child))

    return () => observer.disconnect()
  }, [])

  return (
    <div className="main-container" ref={mainContainerRef}>
      <div id="b2cScreen">
        <div id="root-layout">
          <Header />
          <Navbar />
          <div id="page-container" onClick={() => setOpenSideNavbar(false)}>
            <Switch>
              <Route path={`${url}`} component={Home} exact />
              <Route path={`${url}hotels`} component={Hotels} exact />
              <Route
                path={`${url}terms`}
                component={TermsAndConditions}
                exact
              />
              <Route
                path={`${url}refund`}
                component={RefundAndCancellationPolicy}
                exact
              />
              <Route path={`${url}privacy`} component={PrivacyPolicy} exact />
              <Route path={`${url}contactUs`} component={ContactPage} />
              <Route path={`${url}aboutUs`} component={AboutUs} />
              <Route path={`${url}innerCircle`}>
                <Route path={`${url}`} component={LandingPage} />
              </Route>
              <Route path={`${url}uttarakhand`} component={Destination} />
              <Redirect to="/" />
            </Switch>

            {/*            
              <Modal
                open={true}
                onClose={onClose}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "fit-content",
                    zIndex: "10000",
                    position: "relative",
                    backdropFilter: "blur(10px)",
                    padding: "1rem",
                    borderRadius: "0.8rem",
                  }}
                >
                  <h1 style={{ color: "red" }}>
                    Cant find what yo are looking for ?
                  </h1>
                  <h4 style={{ color: "red" }}>Drop in the details below</h4>
                  <QueryForm />
                </div>
              </Modal> */}
          </div>
        </div>
        <ContactModal />
        <Footer />
        {/* <ContactButton /> */}
      </div>
    </div>
  )
}

export default RoutesB2C
