import React, { useState, useEffect } from "react"
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import {
  Stack,
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  TableHead,
  OutlinedInput,
  InputAdornment,
  Tooltip,
} from "@mui/material"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { Link } from "react-router-dom"
import { format, differenceInDays } from "date-fns"
import TablePagination from "@mui/material/TablePagination"
import Api from "../../Service/Api"
import { formatter } from "../../utils/formatNumber"
import { uniqueId } from "lodash"
import { twnButtonStyles } from "../../utils/townoStyle"
import SearchIcon from "@material-ui/icons/Search"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import TransferListMobile from "./TransferListMobileUI"
import Loader from "../../components/Loader/Loader"
import { filter } from "lodash"
import { IconButton } from "@material-ui/core"
import { ReactComponent as TransferLogo } from "../../assets/logo/Transfer (1).svg"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import TransferPDFIcon from "../../assets/Icons/voucherIcons/Transfer.png"
import TransferPDF from "../../components/pdfVoucherComponents/TransferPDF.component"
import html2pdf from "html2pdf.js"
import ReactDOMServer from "react-dom/server"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f46d25",
    color: "#fff",
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: "5px",
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: "1px solid #eee",
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // }
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 32,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `#f46d25 !important`,
  },
}))

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_transferData) =>
        (_transferData.clientName &&
          _transferData.clientName
            .toLowerCase()
            .indexOf(query.toLowerCase()) !== -1) ||
        (_transferData.transferId &&
          _transferData.transferId
            .toLowerCase()
            .indexOf(query.toLowerCase()) !== -1) ||
        (_transferData.clientPhoneNumber &&
          _transferData.clientPhoneNumber
            .toLowerCase()
            .indexOf(query.toLowerCase()) !== -1)
    )
  }
  return stabilizedThis.map((el) => el[0])
}

export default function TransferList() {
  var uniqueid = localStorage.getItem("unique_id")
  let hasAdmin = localStorage.getItem("role")

  const [tableData, setTableData] = useState([])
  const [textToSearch, setTextToSearch] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  var createdBy = localStorage.getItem("auth")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    let url = `/stafftransferList/${uniqueid}/${createdBy}`
    if (
      hasAdmin === "Admin" ||
      hasAdmin === "Super Admin" ||
      hasAdmin === "Finance Team" ||
      hasAdmin === "Agent Admin"
    ) {
      url = `/transferList/${uniqueid}`
    }
    Api.get(url).then((res) => {
      setTableData(res.data)
      setLoading(false)
    })
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  console.log(tableData)

  const filteredTabledata = applySortFilter(
    tableData,
    getComparator(order, orderBy),
    textToSearch
  )

  const [isDownloading, setIsDownloading] = useState(false)

  const DownloadTransfer = (data) => {
    setIsDownloading(true)
    console.log(data)
    let data1 = data.transferId
    const link = document.createElement("a")
    link.target = "_blank"
    link.download = `${data1}.pdf`
    Api.get(`transfergenpdf/${uniqueid}/${data1}`, {
      responseType: "blob",
    })
      .then((res) => {
        console.log(res)
        link.href = URL.createObjectURL(new Blob([res.data], { type: "pdf" }))
        link.click()
        setIsDownloading(false)
      })
      .catch((error) => {
        if (error) {
          setIsDownloading(false)
          alert(error)
        }
      })
  }

  const createTransferPDF = async (id, fileName) => {
    setIsDownloading(true)
    try {
      const logoUrl = await Api.get(`getAgentLogo/${uniqueid}`)
      const pdfData = await Api.get(`/transferDetail/${uniqueid}/${id}`)
      const termsandcondition = await Api.get(
        `agenthoteltermandcondition/${uniqueid}/transferTermCondition`
      )

      const cancelationpolicy = await Api.get(
        `agenthoteltermandcondition/${uniqueid}/transferCancellationPolicy`
      )

      let flightDetails = await pdfData.data.flightDetails
      let trainDetails = await pdfData.data.trainDetails
      let cabDetails = await pdfData.data.cabDetails
      let busDetails = await pdfData.data.busDetails

      let pdfComponent = (
        <TransferPDF
          logoUrl={logoUrl.data}
          data={pdfData.data}
          termsandcondition={termsandcondition.data}
          cancelationpolicy={cancelationpolicy.data}
          flightDetails={flightDetails}
          trainDetails={trainDetails}
          cabDetails={cabDetails}
          busDetails={busDetails}
        />
      )
      let jsxString = await ReactDOMServer.renderToStaticMarkup(pdfComponent)

      const pdfOptions = {
        // margin: 10,
        padding: "0",
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 3, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      }

      const element = document.createElement("div")
      element.innerHTML = jsxString

      await html2pdf().from(element).set(pdfOptions).save()
      setIsDownloading(false)
    } catch (error) {
      console.error("Error creating PDF:", error)
      setIsDownloading(false)
      alert(error)
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : width <= 768 ? (
        <TransferListMobile
          data={filteredTabledata}
          textToSearch={textToSearch}
          setTextToSearch={setTextToSearch}
        />
      ) : (
        <div style={twnButtonStyles.allPages}>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ paddingTop: "1.95%" }}
          >
            <Typography
              style={{ ...twnButtonStyles.xlFonts, marginTop: "-9px" }}
            >
              Transfers
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="right"
              alignItems="right"
            >
              <SearchStyle
                value={textToSearch}
                onChange={(e) => {
                  setTextToSearch(e.target.value)
                }}
                placeholder="Search"
                style={twnButtonStyles.smFonts}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ color: "text.disabled" }}
                      color="primary"
                    />
                  </InputAdornment>
                }
              />
              <div
                onClick={() => {
                  window.open("/konnect/transferForm", "_self")
                }}
                style={twnButtonStyles.linkOrangeBtn}
              >
                Create Transfer
              </div>
            </Stack>
          </Stack>

          <Table size="small" style={{ marginTop: "15px" }}>
            <TableHead style={twnButtonStyles.headerStyle}>
              <TableRow>
                <StyledTableCell align="left">Transfer Id</StyledTableCell>
                <StyledTableCell align="left">Client Name</StyledTableCell>
                <StyledTableCell align="left">Phone No.</StyledTableCell>
                {hasAdmin === "Admin" ||
                hasAdmin === "Super Admin" ||
                hasAdmin === "Finance Team" ||
                hasAdmin === "Agent Admin" ? (
                  <StyledTableCell align="left">Agent Name</StyledTableCell>
                ) : null}
                <StyledTableCell align="left">Transfers</StyledTableCell>
                <StyledTableCell align="left">Commission</StyledTableCell>
                <StyledTableCell align="left">Total Amount</StyledTableCell>
                <StyledTableCell align="left">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            {tableData.length > 0 ? (
              <TableBody>
                {filteredTabledata
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {row.transferId}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.clientName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {row.clientPhoneNumber}
                      </StyledTableCell>
                      {hasAdmin === "Admin" ||
                      hasAdmin === "Super Admin" ||
                      hasAdmin === "Finance Team" ||
                      hasAdmin === "Agent Admin" ? (
                        <StyledTableCell align="left">
                          {row.createdBy}
                        </StyledTableCell>
                      ) : null}
                      <StyledTableCell align="left">{row.type}</StyledTableCell>
                      <StyledTableCell align="left">
                        {formatter.format(row.commission)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {formatter.format(row.totalAmount)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="left"
                          style={{ color: "#f46d25" }}
                        >
                          {row.amountRefunded > 0 ? (
                            <Link
                              to={{
                                pathname: `/konnect/viewTransfer/${row.transferId}`,
                              }}
                            >
                              <VisibilityIcon style={{ color: "#f46d25" }} />
                            </Link>
                          ) : (
                            <>
                              <div
                                onClick={() => {
                                  window.open(
                                    `/konnect/transferForm/${row.transferId}`,
                                    "_self"
                                  )
                                }}
                              >
                                <EditIcon
                                  style={{
                                    color: "#f46d25",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              <Link
                                to={{
                                  pathname: `/konnect/viewTransfer/${row.transferId}`,
                                }}
                              >
                                <VisibilityIcon style={{ color: "#f46d25" }} />
                              </Link>
                              <div
                                onClick={() => {
                                  window.open(
                                    `/konnect/copyTransferPage/${row.transferId}`
                                  )
                                }}
                              >
                                <Tooltip title="Copy Package" arrow>
                                  <FileCopyIcon
                                    style={{
                                      color: "#f46d25",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              {/* <IconButton
                                style={{ color: "#f46d25", padding: "0" }}
                                onClick={() => DownloadTransfer(row)}
                                title="Download Transfer Pdf "
                              >
                                <TransferLogo style={{ height: "28px" }} />
                              </IconButton> */}

                              <IconButton
                                style={{ padding: "0" }}
                                title="Download Transfer PDF"
                                onClick={() =>
                                  createTransferPDF(
                                    `${row.transferId}`,
                                    `${row.transferId}_TR.pdf`
                                  )
                                }
                              >
                                <img
                                  src={TransferPDFIcon}
                                  style={{
                                    height: "20px",
                                    width: "auto",
                                    padding: "0",
                                  }}
                                />
                              </IconButton>
                            </>
                          )}
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            ) : (
              "Search Not Found"
            )}
          </Table>
          <TablePagination
            component="div"
            count={tableData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ScreenLoader open={isDownloading} />
        </div>
      )}
    </>
  )
}
