import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import DateFnsUtils from "@date-io/date-fns"
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  AddCircleOutline,
  DeleteForeverOutlined,
  SearchOutlined,
} from "@material-ui/icons"
import { Autocomplete } from "@material-ui/lab"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Dialog, Grid, Stack } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { format, parse } from "date-fns"
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik"
import _ from "lodash"
import MuiPhoneNumber from "material-ui-phone-number"
import PropTypes from "prop-types"
import { Suspense, lazy, useEffect, useMemo, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import API from "../../Service/Api"
import Loader from "../../components/Loader/Loader"
import { clientListInitial } from "../../redux/actions/clientAction"
import { getPaymentModeOptions } from "../../redux/actions/commonFeatureDropdown.action"
import { formatCurrency } from "../../utils/formatNumber"
import PreviewPage from "../Transfer/PreviewPage"
import TransferByFlightForm from "./TransferByFlightFrom.component"
import { wait } from "../../utils/wait"
import * as Yup from "yup"
import { toast } from "react-toastify"
import TransferRefund from "../Transfer/TransferRefund"

const TransferByTrainfForm = lazy(() =>
  wait(1000).then(() => import("./TransferByTrainForm.component"))
)
const TransferByRoadForm = lazy(() =>
  wait(1000).then(() => import("./TransferByRoadForm.component"))
)

const HotelDetailsForm = lazy(() =>
  wait(1000).then(() => import("./HotelDetailsForm"))
)

const useStyles = makeStyles(() => ({
  root: {
    margin: "0rem",
    padding: "4rem 1.5rem",
  },
  tabs: {
    background: "#EEEEEE",
    textDecoration: "none",
    boxShadow: "none !important",
    borderRadius: "0.5em 0.5em 0em 0em",
  },
  appBar: {
    background: "none",
    textDecoration: "none",
    border: "none !important",
    boxShadow: "none !important",
    zIndex: "1",
    marginTop: "-1em !important",
  },
  tab: {
    textDecoration: "none",
    // color: "#f46d25",
    fontWeight: 600,
  },
  dataGrid: {
    // "& .MuiDataGrid-columnHeader": {
    //   background: "red",
    // },
    // "& .MuiDataGrid-columnHeaderTitle": {
    //   color: "#f46d25",
    //   fontWeight: "bold",
    //   fontSize: "1rem",
    // },
  },
  error: {
    marginBottom: "3px",
    color: "red",
    textDecoration: "italic",
    fontSize: "1rem",
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{
        width: "100%",
      }}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const transferType = (values) => {
  const flightAmount = values?.flightDetails.reduce((sum, flight) => {
    return sum + parseFloat(flight.flightAmount ?? 0)
  }, 0)

  const trainAmount = values?.trainDetails.reduce((sum, train) => {
    return sum + parseFloat(train.trainAmount ?? 0)
  }, 0)

  const busAmount = values?.busDetails.reduce((sum, bus) => {
    return sum + parseFloat(bus.busAmount ?? 0)
  }, 0)

  const cabAmount = values?.cabDetails.reduce((sum, cab) => {
    return sum + parseFloat(cab.cabAmount ?? 0)
  }, 0)
  const hotelAmount = values?.hotelDetails.reduce((sum, hotel) => {
    return sum + parseFloat(hotel.hotelAmount ?? 0)
  }, 0)

  const map = new Map()

  if (flightAmount <= 0) {
    if (map.has("Flight")) {
      map.delete("Flight")
    }
  } else {
    map.set("Flight", "Flight")
  }

  if (trainAmount <= 0) {
    if (map.has("Train")) {
      map.delete("Train")
    }
  } else {
    map.set("Train", "Train")
  }

  if (busAmount <= 0) {
    if (map.has("Bus")) {
      map.delete("Bus")
    }
  } else {
    map.set("Bus", "Bus")
  }

  if (cabAmount <= 0) {
    if (map.has("Cab")) {
      map.delete("Cab")
    }
  } else {
    map.set("Cab", "Cab")
  }

  if (hotelAmount <= 0) {
    if (map.has("Hotel")) {
      map.delete("Hotel")
    }
  } else {
    map.set("Hotel", "Hotel")
  }

  const keys = Array.from(map.keys())
  return keys.join(",")
}

const paymentsReducer = (state, action) => {
  const { name, value } = action
  return { ...state, [name]: value }
}

const TansferFormValidationSchema = Yup.object().shape({
  basicDetails: Yup.array().of(
    Yup.object().shape({
      clientName: Yup.string().required("Select any Client"),
      clientMobileNo: Yup.string().required("Required"),
      clientEmail: Yup.string().email("Enter valid email").required("Required"),
    })
  ),
  flightDetails: Yup.array().of(
    Yup.object().shape({
      flightTrip: Yup.string(),
      flightFrom: Yup.string().required("Required"),
      flightTo: Yup.string().required("Required"),
      flightDepartDate: Yup.string().nullable().required("Required"),
      // flightReturnDate: Yup.string().when("flightTrip", {
      //   is: "Round",
      //   then: Yup.string().nullable().required("Required"),
      // }),
      flightPnr: Yup.string().required("Required"),
      flightAdults: Yup.number()
        .required("Required")
        .min(0, "The value should be greater thanor equal to zero"),
      flightAmount: Yup.number()
        .required("Required")
        .min(0, "The value should be greater than or equal to zero"),
      flightComission: Yup.number()
        .required("Required")
        .min(0, "The value should be greater than or equal to zero"),
    })
  ),

  busDetails: Yup.array().of(
    Yup.object().shape({
      busFrom: Yup.string(),
      busTo: Yup.string(),
      busAdults: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
      busAmount: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
      busCommission: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
    })
  ),
  cabDetails: Yup.array().of(
    Yup.object().shape({
      cabFrom: Yup.string(),
      cabTo: Yup.string(),
      cabAdults: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
      cabAmount: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
      cabCommission: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
    })
  ),
  hotelDetails: Yup.array().of(
    Yup.object().shape({
      hotelAdults: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
      // hotelCheckIn: Yup.string().required("Required"),
      // hotelCheckOut: Yup.string().required("Required"),

      hotelAmount: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
      hotelCommission: Yup.number().min(
        0,
        "The value should be greater than or equal to zero"
      ),
    })
  ),
})

const PaymentStateSchema = Yup.object().shape({
  paidAmount: Yup.number().min(
    0,
    "The value should be greater than or equal to zero"
  ),
})

const TransferFormV2 = () => {
  const styles = useStyles()
  const uniqueId = localStorage.getItem("unique_id")
  const dispatch = useDispatch()
  const history = useHistory()
  const { transferId } = useParams()
  const clientReducer = useSelector(({ clientList }) => clientList)
  const { paymentModeOptions } = useSelector(
    ({ paymentModeOptionsReducer }) => paymentModeOptionsReducer
  )
  const [openPreview, setOpenPreview] = useState(false)

  const memoizedPaymentModeOptions = useMemo(
    () => paymentModeOptions,
    [paymentModeOptions]
  )

  const [paymentState, paymentDispatch] = useReducer(paymentsReducer, {
    id: null,
    paidAmount: 0,
    paymentMode: "",
    paymentRefNo: "",
  })

  const [initialValues, setInitialValues] = useState({
    id: null,
    amountRefunded: null,
    amountRetained: null,
    corporate: false,
    basicDetails: [
      {
        id: "",
        clientName: "",
        clientMobileNo: "",
        clientEmail: "",
        clientAltNo: "",
      },
    ],
    bookingId: "",
    busDetails: [
      {
        id: "",
        busName: "",
        busFrom: " ",
        busTo: " ",
        busSeatNo: "",
        busAdults: 0,
        busChild: 0,
        busAmount: 0,
        busCommission: 0,
      },
    ],
    cabDetails: [
      {
        id: "",
        cabFrom: " ",
        cabTo: " ",
        cabTrip: "",
        cabType: "",
        cabVehicle: "",
        cabAdults: 0,
        cabChild: 0,
        cabAmount: 0,
        cabCommission: 0,
      },
    ],
    clientName: "",
    clientPhoneNumber: "",
    commission: 0,
    flightDetails: [
      {
        id: "",
        flightTrip: "",
        flightName: "",
        flightFrom: " ",
        flightTo: " ",
        flightDepartDate: null,
        flightReturnDate: null,
        flightPnr: " ",
        flightAdults: 0,
        flightChild: 0,
        flightInclusion: 0,
        flightAmount: 0,
        flightComission: 0,
      },
    ],
    hotelDetails: [
      {
        id: null,
        hotelName: "",
        hotelDestination: "",
        hotelAdults: 0,
        hotelChild: 0,
        hotelCheckIn: null,
        hotelCheckOut: null,
        hotelVendorAmount: 0,
        hotelAmount: 0,
        hotelCommission: 0,
      },
    ],
    revisedCommission: 0,
    totalAmount: 0,
    trainDetails: [
      {
        id: "",
        traintravelClass: "",
        trainName: "",
        trainFrom: " ",
        trainTo: " ",
        trainDepartDate: null,
        trainPnr: " ",
        trainAdults: 0,
        trainChild: 0,
        trainInclusion: 0,
        trainAmount: 0,
        trainComission: 0,
      },
    ],
    transferPaymentDetails: [],

    transferStatus: null,
    transferTeamCondition: {
      id: null,
      uniqueId,
      transferId: "",
      teamCondition: "",
      cancellationPolicy: "",
      createdBy: "",
      createdAt: "",
      updatedBy: null,
      updatedAt: null,
    },
    type: "",
    uniqueId,
    updatedBy: "",
    updatedAt: "",
  })

  // refund starts
  const [amountRefunded, setAmountRefunded] = useState(0)
  const [amountRetained, setAmountRetained] = useState(0)
  const [revisedCommission, setRevisedCommission] = useState(0)
  const [showRefund, setShowRefund] = useState(false)
  const [openRefund, setOpenRefund] = useState(false)

  const handleRefund = () => {
    const postData = {
      uniqueId: uniqueId,
      packageId: transferId,
      amountRefunded: amountRefunded,
      amountRetained: amountRetained,
      revisedCommission: revisedCommission,
    }
    // console.log("postdata|refund: ", postData)

    try {
      API.post("cancelTransferDetails", postData).then((res) => {
        toast.success("Cancellation Success Updated")
      })
    } catch (error) {
      console.log(error)
    } finally {
      setOpenRefund(false)
      setShowRefund(true)
    }
  }
  // refund ends

  const fetchTermsAndConditionsForNewTransfer = async () => {
    setIsFetching(true)

    try {
      const { data: teamCondition } = await API.get(
        `agenthoteltermandcondition/${uniqueId}/transferTermCondition`
      )

      const { data: cancellationPolicy } = await API.get(
        `agenthoteltermandcondition/${uniqueId}/transferCancellationPolicy`
      )

      setInitialValues((currentValues) => ({
        ...currentValues,
        transferTeamCondition: {
          ...currentValues.transferTeamCondition,
          teamCondition,
          cancellationPolicy,
        },
      }))

      setIsFetching(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!transferId) {
      fetchTermsAndConditionsForNewTransfer()
    }
  }, [])

  const [transferTabValue, setTransferTabValue] = useState("flight")
  const tabPanelDetails = [
    { label: "Flight", value: "flight", component: <TransferByFlightForm /> },
    { label: "Train", value: "train", component: <TransferByTrainfForm /> },
    {
      label: "Road Transport",
      value: "road",
      component: <TransferByRoadForm />,
    },
    { label: "Hotel Details", value: "hotel", component: "Hotel Details" },
  ]

  const handleChangeTransferTabValue = (event, newValue) => {
    setTransferTabValue(newValue)
  }

  const alteredClientList = useMemo(() => {
    return clientReducer?.clientLists?.map((c) => ({
      title: `${c?.firstName} ${c?.lastName} (${c?.mail})`,
      mobile: c.mobile,
      clientName: `${_.capitalize(c.title)} ${c?.firstName} ${c?.lastName}`,
      mail: c?.mail,
    }))
  }, [clientReducer])

  const totalTransferBookingAmount = (values) => {
    const flightDetails = values?.flightDetails.reduce((sum, flight) => {
      return sum + parseFloat(flight.flightAmount ?? 0)
    }, 0)

    const trainDetails = values?.trainDetails.reduce((sum, train) => {
      return sum + parseFloat(train.trainAmount ?? 0)
    }, 0)

    const busDetails = values?.busDetails.reduce((sum, bus) => {
      return sum + parseFloat(bus.busAmount ?? 0)
    }, 0)

    const cabDetails = values?.cabDetails.reduce((sum, cab) => {
      return sum + parseFloat(cab.cabAmount ?? 0)
    }, 0)
    const hotelDetails = values?.hotelDetails.reduce((sum, hotel) => {
      return sum + parseFloat(hotel.hotelAmount ?? 0)
    }, 0)

    return flightDetails + trainDetails + busDetails + cabDetails + hotelDetails
  }

  const totalTransferCommissionAmount = (values) => {
    const flightDetails = values?.flightDetails.reduce((sum, flight) => {
      return sum + parseFloat(flight.flightComission ?? 0)
    }, 0)

    const trainDetails = values?.trainDetails.reduce((sum, train) => {
      return sum + parseFloat(train.trainComission ?? 0)
    }, 0)

    const busDetails = values?.busDetails.reduce((sum, bus) => {
      return sum + parseFloat(bus.busCommission ?? 0)
    }, 0)

    const cabDetails = values?.cabDetails.reduce((sum, cab) => {
      return sum + parseFloat(cab.cabCommission ?? 0)
    }, 0)
    const hotelDetails = values?.hotelDetails.reduce((sum, hotel) => {
      return sum + parseFloat(hotel.hotelCommission ?? 0)
    }, 0)

    return flightDetails + trainDetails + busDetails + cabDetails + hotelDetails
  }

  const totalPaidAmount = (values) => {
    return values?.transferPaymentDetails?.reduce(
      (sum, t) => sum + parseFloat(t.paidAmount),
      0
    )
  }

  // const columns = [
  //   {
  //     field: "Total Transfer Booking Amount",
  //     headerClassName: "super-app-theme--header",
  //     headerAlign: "left",
  //     flex: 1,
  //     color: "#f46d25 !important",
  //   },
  //   {
  //     field: "Total Amount Paid",
  //     headerClassName: "super-app-theme--header",
  //     headerAlign: "left",
  //     flex: 1,
  //   },

  //   {
  //     field: "Commission",
  //     headerClassName: "super-app-theme--header",
  //     headerAlign: "left",
  //     flex: 1,
  //   },
  // ]

  // const rows = (values) => [
  //   {
  //     id: 1,
  //     ["Total Transfer Booking Amount"]: totalTransferBookingAmount(values),
  //     ["Total Amount Paid"]: "Carter",
  //     Commission: totalTransferCommissionAmount(values),
  //   },
  // ]

  const transactionHistoryColumn = [
    {
      field: "Date",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
      hidable: false,
    },
    {
      field: "Amount Paid",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "Payment Mode",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
    },
    {
      field: "Reference Number",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      flex: 1,
    },
  ]

  const transactionHistoryRow = (values) => {
    return values?.transferPaymentDetails?.map((t, idx) => ({
      id: idx + 1,
      Date: t.createdAt
        ? format(
            parse(t.createdAt, "yyyy-MM-dd HH:mm:ss", new Date()),
            "dd MMM yy, hh:mm a"
          )
        : null,
      ["Amount Paid"]: formatCurrency(t.paidAmount),
      ["Payment Mode"]: t.paymentMode,
      ["Reference Number"]: t.paymentRefNo,
    }))
  }

  useEffect(() => {
    dispatch(clientListInitial(uniqueId))
    dispatch(getPaymentModeOptions(uniqueId))
    if (transferId) {
      fetchTransferDetails()
    }
  }, [])

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const [isFetching, setIsFetching] = useState(false)

  const fetchTransferDetails = async () => {
    setIsFetching(true)
    try {
      const res = await API.get(`/transferDetail/${uniqueId}/${transferId}`)
      if (res.status == 200) {
        setInitialValues(res?.data)
        setIsFetching(false)
        return
      }
      return Promise.reject(res)
    } catch (error) {
      console.error(error)
    }
  }

  const role = localStorage.getItem("role")

  const handleSearch = (values, setFieldValue) => async () => {
    let url

    switch (role) {
      case "Hotel Admin": {
        url = "bookingDetailsForHotelLogin"
        break
      }
      case "Agent Admin": {
        url = "agentBookingById"
        break
      }
      default: {
        url = "bookingdetails"
        break
      }
    }
    try {
      const { data } = await API.get(`${url}/${uniqueId}/${values.bookingId}`)

      if (_.isEmpty(data) || _.isNull(data))
        return toast.error("Invalid Booking ID")

      const res = await API.get(`checkTransferBookingId/${values.bookingId}`)

      if (res.data == "Already Added")
        return toast.warn("Transfer ID already exists for this Booking ID")

      const alteredClientName = data?.clientName
        ?.replace(/\.|\t/g, " ")
        .trim()
        .toLowerCase()
        .split(" ")
        .filter((f) => f !== "")
        .join(" ")

      if (!_.isEmpty(alteredClientList)) {
        const findClient = await alteredClientList.find(
          (c) => c.clientName.toLowerCase() === alteredClientName.toLowerCase()
        )

        console.log(findClient)
        setFieldValue(`basicDetails[0].clientName`, findClient.clientName)
        setFieldValue(`basicDetails[0].clientEmail`, data.clientEmail)
        setFieldValue(`basicDetails[0].clientMobileNo`, data.clientMobile)
      }

      // setInitialValues((currentValues) => ({
      //   ...currentValues,
      //   basicDetails: [
      //     {
      //       id: "",
      //       clientName: findClient.clientName,
      //       clientMobileNo: data.clientMobile,
      //       clientEmail: data.clientEmail,
      //       clientAltNo: "",
      //     },
      //   ],
      // }))
    } catch (error) {
      if (_.isEqual(error.status == 500))
        return toast.error("Invalid Booking Id")
      toast.error(error.message)
    }
  }

  const validatePaymentState = async () => {
    try {
      await PaymentStateSchema.validate(paymentState, { abortEarly: false })
      return {}
    } catch (err) {
      return err.inner.reduce((acc, error) => {
        acc[error.path] = error.message
        return acc
      }, {})
    }
  }

  const handleSubmit = (fields, { setSubmitting }) => {
    setSubmitting(true)

    fields.type = transferType(fields)
    fields.totalAmount = totalTransferBookingAmount(fields)
    fields.commission = totalTransferCommissionAmount(fields)
    fields.transferPaymentDetails = [
      ...fields.transferPaymentDetails,
      paymentState,
    ]
    fields.amountPaid =
      totalPaidAmount(fields) + parseFloat(paymentState.paidAmount)
    fields.clientName = fields.basicDetails[0].clientName
    fields.clientPhoneNumber = fields.basicDetails[0].clientMobileNo
    fields.createdBy = localStorage.getItem("auth")
    fields.createdAt = format(new Date(), "yyyy-MM-dd hh:mm:ss")
    fields.updateBy = null
    fields.updateAt = null

    let postData = { ...fields }

    if (transferId) {
      postData = { ...postData, transferId: transferId }
    }

    let url = role === "Hotel Admin" ? "hotelTransferData" : "transferData"

    console.log({ postData })

    try {
      API.post(url, postData).then(async (res) => {
        if (res.status === 200) {
          transferId
            ? toast.success("Transfer Details Updated Successfully")
            : toast.success("Transfer Details Created Successfully")

          console.log("TransferForm|res.data", res.data)
          setSubmitting(false)
          history.push("/konnect/transferList")
        }
      })
    } catch (error) {
      console.log(error)
      toast.error("Transfer Details Unsuccessfull ", error.code)
    } finally {
      setSubmitting(false)
    }
  }

  if (isFetching) {
    return (
      <h1 className={styles.root}>
        <Loader />
      </h1>
    )
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div container className={styles.root}>
        <Typography variant="h4" color="primary">
          Transfer Form
        </Typography>

        <Formik
          initialValues={initialValues}
          // validationSchema={TansferFormValidationSchema}
          validateOnChange
          // enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            isSubmitting,
            fields,
            isValid,
            errors,
            touched,
            setErrors,
          }) => (
            <Form>
              <Grid container rowSpacing={2} padding={"2rem 0rem"}>
                <Grid item container md={12}>
                  <Grid item md={3}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      name="bookingId"
                      size="small"
                      label="Booking ID"
                      fullWidth
                      type="search"
                      value={values.bookingId}
                      disabled={transferId}
                      InputProps={{
                        endAdornment: !transferId ? (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              disabled={transferId || !values.bookingId}
                              onClick={handleSearch(values, setFieldValue)}
                            >
                              <SearchOutlined color="primary" />
                            </IconButton>
                          </InputAdornment>
                        ) : null,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container md={12}>
                  <Grid item md={12} paddingBottom={2}>
                    <Typography variant="h5" color="primary">
                      Basic Details
                    </Typography>
                  </Grid>
                  <Grid item md={12} padding={1}>
                    <Field
                      as={FormControlLabel}
                      control={
                        <Field
                          as={Checkbox}
                          name="corporate"
                          color="primary"
                          size="small"
                          checked={values.corporate}
                        />
                      }
                      label="Corporate"
                    />
                  </Grid>
                  <FieldArray
                    name="basicDetails"
                    render={(arrayHelpers) => (
                      <Grid
                        item
                        container
                        md={12}
                        rowSpacing={1}
                        border="1px solid #546e7a"
                        borderRadius="0.75em"
                        padding={1}
                      >
                        {values.basicDetails.map((basic, bi) => (
                          <Grid
                            item
                            container
                            md={12}
                            key={bi}
                            columnSpacing={2}
                          >
                            <Grid item md={12} textAlign="end">
                              {values.basicDetails?.length !== 1 &&
                                (transferId && bi == 0 ? null : (
                                  <IconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(bi)}
                                    style={{ padding: "0rem" }}
                                  >
                                    <DeleteForeverOutlined color="primary" />
                                  </IconButton>
                                ))}
                              {values.basicDetails.length - 1 == bi && (
                                <IconButton
                                  style={{ padding: "0rem" }}
                                  size="small"
                                  onClick={() => {
                                    arrayHelpers.push({
                                      id: null,
                                      uniqueId,
                                      clientName: "",
                                      clientEmail: "",
                                      clientMobileNo: "",
                                      clientAltNo: "",
                                      transferId: "",
                                    })
                                  }}
                                >
                                  <AddCircleOutline
                                    color="primary"
                                    style={{ padding: "0rem" }}
                                  />
                                </IconButton>
                              )}
                            </Grid>

                            <Grid item md={3}>
                              {bi == 0 ? (
                                !transferId ? (
                                  <>
                                    {console.log(
                                      values.basicDetails[0].clientName
                                    )}
                                    <Field
                                      as={Autocomplete}
                                      options={alteredClientList}
                                      getOptionLabel={(option) => option?.title}
                                      getOptionSelected={(option) =>
                                        option.clientName == basic.clientName
                                      }
                                      autoSelect={true}
                                      isOptionEqualToValue={(option, value) =>
                                        option.clientName == value
                                      }
                                      size="small"
                                      value={alteredClientList?.find(
                                        (c) =>
                                          c.clientName.toLowerCase() ===
                                          values.basicDetails[
                                            bi
                                          ].clientName.toLowerCase()
                                      )}
                                      onChange={(event, value) => {
                                        setFieldValue(
                                          `basicDetails[${bi}].clientName`,
                                          value.clientName
                                        )
                                        setFieldValue(
                                          `basicDetails[${bi}].clientMobileNo`,
                                          value.mobile
                                        )
                                        setFieldValue(
                                          `basicDetails[${bi}].clientEmail`,
                                          value.mail
                                        )
                                      }}
                                      loading={clientReducer?.loading}
                                      renderInput={(params) => (
                                        <Field
                                          as={TextField}
                                          {...params}
                                          label="Client Name"
                                          variant="outlined"
                                          fullWidth
                                          InputLabelProps={{ shrink: true }}
                                          name={`basicDetails[${bi}].clientName`}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                              <>
                                                {clientReducer?.loading ? (
                                                  <CircularProgress
                                                    color="primary"
                                                    size={20}
                                                  />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                              </>
                                            ),
                                          }}
                                          required
                                        />
                                      )}
                                    />
                                  </>
                                ) : (
                                  <Field
                                    as={TextField}
                                    fullWidth
                                    value={basic.clientName}
                                    variant="outlined"
                                    size="small"
                                    label="Client Name"
                                    disabled={transferId}
                                    required
                                  />
                                )
                              ) : (
                                <Field
                                  as={Autocomplete}
                                  options={alteredClientList}
                                  getOptionLabel={(option) => option?.title}
                                  size="small"
                                  value={alteredClientList?.find(
                                    (c) =>
                                      c.clientName.toLowerCase() ===
                                      basic.clientName.toLowerCase()
                                  )}
                                  getOptionSelected={(option) =>
                                    option.clientName == basic.clientName
                                  }
                                  onChange={(event, value) => {
                                    setFieldValue(
                                      `basicDetails[${bi}].clientName`,
                                      value.clientName
                                    )
                                    setFieldValue(
                                      `basicDetails[${bi}].clientMobileNo`,
                                      value.mobile
                                    )
                                    setFieldValue(
                                      `basicDetails[${bi}].clientEmail`,
                                      value.mail
                                    )
                                  }}
                                  loading={clientReducer?.loading}
                                  renderInput={(params) => (
                                    <Field
                                      as={TextField}
                                      {...params}
                                      label="Client Name"
                                      variant="outlined"
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      name={`basicDetails[${bi}].clientName`}
                                      //   onChange={handleChange}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {clientReducer?.loading ? (
                                              <CircularProgress
                                                color="primary"
                                                size={20}
                                              />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              )}
                              <ErrorMessage
                                name={`basicDetails[${bi}].clientName`}
                              >
                                {(msg) => (
                                  <div className={styles.error}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </Grid>
                            <Grid item md={3}>
                              <Field
                                as={MuiPhoneNumber}
                                fullWidth
                                required
                                variant="outlined"
                                defaultCountry={"in"}
                                value={basic.clientMobileNo}
                                name={`basicDetails[${bi}].clientMobileNo`}
                                size="small"
                                label="Client Number"
                                onChange={(value) =>
                                  setFieldValue(
                                    `basicDetails[${bi}].clientMobileNo`,
                                    value
                                  )
                                }
                                disabled={transferId && bi == 0}
                              />
                              <ErrorMessage
                                name={`basicDetails[${bi}].clientMobileNo`}
                              >
                                {(msg) => (
                                  <div className={styles.error}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </Grid>
                            <Grid item md={3}>
                              <Field
                                as={TextField}
                                variant="outlined"
                                name={`basicDetails[${bi}].clientEmail`}
                                size="small"
                                label="Client Email"
                                fullWidth
                                type="email"
                                required
                                disabled={transferId && bi == 0}
                              />
                              <ErrorMessage
                                name={`basicDetails[${bi}].clientEmail`}
                              >
                                {(msg) => (
                                  <div className={styles.error}>{msg}</div>
                                )}
                              </ErrorMessage>
                            </Grid>
                            <Grid item md={3}>
                              <Field
                                as={MuiPhoneNumber}
                                fullWidth
                                variant="outlined"
                                defaultCountry={"in"}
                                name={`basicDetails[${bi}].clientAltNo`}
                                value={basic.clientAltNo}
                                size="small"
                                label="Alt Number"
                                onChange={(value) =>
                                  setFieldValue(
                                    `basicDetails[${bi}].clientAltNo`,
                                    value
                                  )
                                }
                                disabled={transferId && bi == 0}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  />
                </Grid>

                {/**Transfer details starts */}
                <Grid
                  item
                  container
                  border="1px solid #546e7a"
                  marginTop={3}
                  paddingTop="0em"
                  borderRadius="0.5em"
                >
                  <AppBar position="static" className={styles.appBar}>
                    <Tabs
                      value={transferTabValue}
                      onChange={handleChangeTransferTabValue}
                      className={styles.tabs}
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      {tabPanelDetails.map((tab, index) => (
                        <Tab
                          className={styles.tab}
                          label={tab.label}
                          value={tab.value}
                          {...a11yProps(tab.value)}
                          key={index}
                        />
                      ))}
                    </Tabs>
                  </AppBar>

                  <TabPanel value={transferTabValue} index={"flight"}>
                    <TransferByFlightForm
                      data={values.flightDetails}
                      setFieldValue={setFieldValue}
                      styles={styles}
                    />
                  </TabPanel>

                  <TabPanel value={transferTabValue} index={"train"}>
                    <Suspense fallback={<Loader />}>
                      <TransferByTrainfForm
                        data={values.trainDetails}
                        setFieldValue={setFieldValue}
                        styles={styles}
                        errors={errors}
                        touched={touched}
                      />
                    </Suspense>
                  </TabPanel>

                  <TabPanel value={transferTabValue} index={"road"}>
                    <Suspense fallback={<Loader />}>
                      <TransferByRoadForm
                        cabDetails={values.cabDetails}
                        busDetails={values.busDetails}
                        setFieldValue={setFieldValue}
                        styles={styles}
                        errors={errors}
                        touched={touched}
                      />
                    </Suspense>
                  </TabPanel>

                  <TabPanel value={transferTabValue} index={"hotel"}>
                    <Suspense fallback={<Loader />}>
                      <HotelDetailsForm
                        data={values.hotelDetails}
                        setFieldValue={setFieldValue}
                        styles={styles}
                        errors={errors}
                        touched={touched}
                      />
                    </Suspense>
                  </TabPanel>
                </Grid>
                {/**Transfer details ends */}

                {/**Data Grid starts*/}
                <Grid item container borderRadius="0.5em">
                  {/* <div style={{ height: 110, width: "100%" }}>
                    <DataGrid
                      rows={rows(values)}
                      columns={columns}
                      hideFooterPagination
                      // disableColumnSelector={true}
                      disableColumnFilter
                      disableColumnMenu={true}
                      disableVirtualization
                      disableSelectionOnClick
                      hideFooter
                      className={styles.dataGrid}
                    />
                  </div> */}

                  <Grid
                    item
                    container
                    md={12}
                    border="1px solid #546e7a"
                    padding={1}
                    style={{ background: "#EEEEEE" }}
                    fontWeight={600}
                    borderRadius="0.5em 0.5em 0em 0em"
                  >
                    <Grid item md={4}>
                      <Typography variant="subtitle1" color="primary">
                        Total Transfer Booking Amount
                      </Typography>
                    </Grid>
                    {transferId && (
                      <Grid item md={4}>
                        <Typography variant="subtitle1" color="primary">
                          Total Amount Paid
                        </Typography>
                      </Grid>
                    )}
                    <Grid item md={4}>
                      <Typography variant="subtitle1" color="primary">
                        Commission
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    md={12}
                    border="1px solid #546e7a"
                    padding={1}
                    borderTop="none"
                    borderRadius="0em 0em 0.5em 0.5em "
                  >
                    <Grid item md={4}>
                      {formatCurrency(totalTransferBookingAmount(values))}
                    </Grid>
                    {transferId && (
                      <Grid item md={4}>
                        {formatCurrency(totalPaidAmount(values))}
                      </Grid>
                    )}
                    <Grid item md={4}>
                      {formatCurrency(totalTransferCommissionAmount(values))}
                    </Grid>
                  </Grid>
                </Grid>
                {/**Data Grid ends*/}

                {/**Transaction Summary Starts*/}
                <Grid item container md={12} marginTop={3}>
                  <Grid item md={12}>
                    <Typography variant="h5" color="primary">
                      Transaction Summary
                    </Typography>
                  </Grid>
                  <Grid item container md={12} spacing={2}>
                    <Grid item container md={4}>
                      <Grid
                        item
                        md={6}
                        alignItems="center"
                        border="1px solid #CCCCCC"
                        padding={0.5}
                        borderRadius="0.5rem 0rem 0rem 0.5rem"
                      >
                        <Typography
                          style={{
                            fontSize: "1rem",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          color="primary"
                        >
                          Amount Paid
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          variant="outlined"
                          value={paymentState.paidAmount}
                          onChange={(e) => {
                            const { name, value } = e.target
                            paymentDispatch({ name: "paidAmount", value })
                          }}
                          size="small"
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          type="number"
                        />
                      </Grid>
                      {errors.paymentState?.paidAmount && (
                        <div className={styles.error}>
                          {errors.paymentState?.paidAmount}
                        </div>
                      )}
                    </Grid>
                    <Grid item container md={4} alignSelf="flex-start">
                      <Grid
                        item
                        md={6}
                        alignItems="center"
                        border="1px solid #CCCCCC"
                        padding={0.5}
                        borderRadius="0.5rem 0rem 0rem 0.5rem"
                      >
                        <Typography
                          style={{
                            fontSize: "1rem",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                          }}
                          color="primary"
                        >
                          Reference Number
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <TextField
                          variant="outlined"
                          name="paymentRefNo"
                          size="small"
                          value={paymentState.paymentRefNo}
                          onChange={(e) => {
                            const { name, value } = e.target
                            paymentDispatch({ name: "paymentRefNo", value })
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Grid item container md={4} alignSelf="flex-start">
                      <Grid
                        item
                        md={6}
                        alignItems="center"
                        border="1px solid #CCCCCC"
                        padding={0.5}
                        borderRadius="0.5rem 0rem 0rem 0.5rem"
                      >
                        <Typography
                          style={{
                            fontSize: "1rem",
                            width: "100%",
                            height: "100%",

                            display: "flex",
                            alignItems: "center",
                          }}
                          color="primary"
                        >
                          Payment Mode
                        </Typography>
                      </Grid>
                      <Grid item md={6}>
                        <Field
                          as={Autocomplete}
                          options={memoizedPaymentModeOptions}
                          getOptionLabel={(option) => option?.label}
                          size="small"
                          value={memoizedPaymentModeOptions?.find(
                            (p) => p.value == paymentState.paymentMode
                          )}
                          onChange={(event, option) => {
                            console.log(option)
                            paymentDispatch({
                              name: "paymentMode",
                              value: option.value,
                            })
                          }}
                          renderInput={(params) => (
                            <Field
                              as={TextField}
                              {...params}
                              variant="outlined"
                              fullWidth
                              InputLabelProps={{ shrink: true }}
                              //   onChange={handleChange}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {clientReducer?.loading ? (
                                      <CircularProgress
                                        color="primary"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {transferId && (
                    <Grid item container md={12} marginTop={3}>
                      <div style={{ width: "100%" }}>
                        <DataGrid
                          columns={transactionHistoryColumn}
                          rows={transactionHistoryRow(values)}
                          hideFooter
                          disableColumnSelector={true}
                          disableSelectionOnClick
                          disableColumnFilter
                          autoHeight={true}
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
                {/**Transaction Summary Ends*/}

                {/**Terms and Conditions Starts */}
                <Grid item container md={12} marginTop={3}>
                  <Grid item md={12}>
                    <Typography variant="h5" color="primary">
                      Terms And Conditions
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Field
                      as={CKEditor}
                      editor={ClassicEditor}
                      name="transferTeamCondition.teamCondition"
                      data={values.transferTeamCondition.teamCondition}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "165px",
                            editor.editing.view.document.getRoot()
                          )
                        })
                      }}
                      onChange={(e, editor) => {
                        const data = editor.getData()

                        setFieldValue(
                          `transferTeamCondition.teamCondition`,
                          data
                        )
                      }}
                    />
                  </Grid>
                </Grid>
                {/**Terms and Conditions Ends */}

                {/**Cancellation Policy Starts */}
                <Grid item container md={12} marginTop={3}>
                  <Grid item md={12}>
                    <Typography variant="h5" color="primary">
                      Cancellation Policy
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Field
                      as={CKEditor}
                      editor={ClassicEditor}
                      name="transferTeamCondition.cancellationPolicy"
                      data={values.transferTeamCondition.cancellationPolicy}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle(
                            "height",
                            "165px",
                            editor.editing.view.document.getRoot()
                          )
                        })
                      }}
                      onChange={(e, editor) => {
                        const data = editor.getData()

                        setFieldValue(
                          `transferTeamCondition.cancellationPolicy`,
                          data
                        )
                      }}
                    />
                  </Grid>
                  {/**Cancellation Policy Ends */}
                </Grid>
              </Grid>

              <Grid item container md={12} justifyContent="center" gap={1}>
                <Button
                  onClick={() => {
                    setOpenPreview(true)
                  }}
                  size="small"
                  disabled={!isValid}
                >
                  Preview
                </Button>

                {transferId && (
                  <Button
                    size="small"
                    onClick={() => {
                      setOpenRefund(true)
                    }}
                  >
                    Cancel Transfer
                  </Button>
                )}

                <Button
                  size="small"
                  onClick={() => history.goBack()}
                  style={{ background: "#000", color: "#fff" }}
                >
                  Back
                </Button>
              </Grid>

              {/* preview dialog starts */}
              <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                sx={{ maxHeight: "100vh" }}
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                // TransitionComponent={Transition}
              >
                <Form>
                  <div>
                    <PreviewPage
                      basicInput={values.basicDetails}
                      flightInput={values.flightDetails}
                      trainInput={values.trainDetails}
                      cabInput={values.cabDetails}
                      busInput={values.busDetails}
                      totalAmount={totalTransferBookingAmount(values)}
                      commission={totalTransferCommissionAmount(values)}
                      packageTeamCondition={values.transferTeamCondition}
                      transferPaymentDetails={paymentState}
                    />
                  </div>
                  <div style={{ backgroundColor: "#eee" }}>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="center"
                      style={{ margin: "1%" }}
                    >
                      <Button
                        size="small"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {isSubmitting && (
                          <i className="fas fa-circle-notch fa-spin" />
                        )}
                        {transferId ? "Update Now" : "Book Now"}
                      </Button>

                      <Button
                        onClick={async () => {
                          setOpenPreview(false)
                        }}
                        size="small"
                        style={{ background: "#000", color: "#fff" }}
                      >
                        Edit Booking
                      </Button>
                    </Stack>
                  </div>
                </Form>
              </Dialog>
              {/* preview dialog ends */}

              {/* refund starts */}
              <TransferRefund
                openRefund={openRefund}
                closeFn={() => setOpenRefund(false)}
                handleRefund={handleRefund}
                totalGrossAmount={totalTransferBookingAmount(values)}
                totalAmountPaid={totalPaidAmount(values)}
                comission={totalTransferCommissionAmount(values)}
                amountRefunded={amountRefunded}
                setAmountRefunded={setAmountRefunded}
                amountRetained={amountRetained}
                setAmountRetained={setAmountRetained}
                revisedCommission={revisedCommission}
                setRevisedCommission={setRevisedCommission}
              />
              {/* refund ends */}
            </Form>
          )}
        </Formik>
      </div>
    </MuiPickersUtilsProvider>
  )
}

export default TransferFormV2
