import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateRangeIcon from "@material-ui/icons/DateRange"
import DateFnsUtils from "@date-io/date-fns"
import { useState } from "react"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import { format } from "date-fns/esm"
import MuiPhoneNumber from "material-ui-phone-number"
import * as Yup from "yup"
import Api from "../../Service/Api"
import { baseurl } from "../../Service/httpCommon"
import { toast } from "react-toastify"
import { useHistory, useParams } from "react-router-dom"
import { useEffect } from "react"
import GenerateInvoiceMobileUI from "./generateInvoiceMobileUI"
import { Grid } from "@mui/material"

const taxOptions = [
  { label: "0%", value: parseFloat(0) },
  { label: "5%", value: parseFloat(0.05) },
  { label: "12%", value: parseFloat(0.12) },
  { label: "18%", value: parseFloat(0.18) },
  { label: "28%", value: parseFloat(0.28) },
]

const GenerateInvoice = () => {
  const classes = useStyles()
  var createdByName = localStorage.getItem("auth")
  const [bookingID, setBookingID] = useState("")
  var uniqueId = localStorage.getItem("unique_id")
  const [errors, setErrors] = useState({})
  const [id, setId] = useState("")
  const [width, setWidth] = useState(window.innerWidth)
  const role = localStorage.getItem("role")

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  const validationSchema = Yup.object().shape({
    bookingID: Yup.string().required("Booking ID Required"),
    businessName: Yup.string().required("Business Name Required"),
    panNo: Yup.string().required("PAN no. required"),
    billerContactNo: Yup.string().required("Enter valid contact no."),
    billerEmail: Yup.string().email("Invalid Email").required("Email required"),
    billingDate: Yup.string().required("Select billing date").nullable(),
    // dueDate: Yup.string().required("Select due date").nullable(),
    shippingAddress: Yup.string().required("Address required"),
    pinCode: Yup.number().required("PIN code required"),
    state: Yup.string().required("State name required"),
    clientName: Yup.string().required("Client Name Required"),
    clientNumber: Yup.string().required("Enter valid contact no."),
    clientEmail: Yup.string().email("Invalid Email").required("Email required"),
    gstNo: Yup.string().required("GST No. Required"),
    clientPanNo: Yup.string().required("PAN no. required"),
    billingAddress: Yup.string().required("Address required"),
    clientPinCode: Yup.number().required("PIN code required"),
    clientState: Yup.string().required("State name required"),
    // termAndConditions: Yup.string().required(
    //   "Please enter terms and conditions"
    // ),
  })

  const { invoiceId } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (invoiceId) {
      console.log(invoiceId)
      const fectchData = async () => {
        try {
          const response = await Api.get(
            `${baseurl}gstdetails/${uniqueId}/${invoiceId}`
          )
          const data = await response.data
          console.log(data)
          setId(data.id)
          setBookingID(data.bookingId)
          setBilledByDetails(data.gstBilledBy)
          setBilledToDetails(data.gstBilledTo)
          setBillingInputValues(data.gstBillingInformation)
          setTermsAndConditions(data.termsAndCondition)
          setAddNotes(data.addNotes)
          setTotalAmountIncGst(data.totalAmountWithGst)
          setTotalTaxAmount(data.totalTax)
          setTotalTaxableAmount(data.totalTaxableAmount)
        } catch (error) {
          console.log(error)
        }
      }
      fectchData()
    }
  }, [invoiceId])

  const handleSearch = async () => {
    let fetchBookingIdDetailsUrl =
      role === "Hotel Admin"
        ? "bookingDetailsForHotelGst"
        : "bookingdetailsforgst"

    const response = await Api.get(
      `${baseurl}gstcheckbookingid/${uniqueId}/${bookingID}`
    )

    const invoiceIdExists = response.data
    console.log(invoiceIdExists)

    if (invoiceIdExists === "no") {
      const bookingListUrl = {
        Admin: "bookingall",
        "Hotel Admin": "getHotelBooking",
        "Agent Admin": "staffagentBookingAll",
        "Sales Manager": "bookingall",
      }[role]
      // let url = role === "Hotel Admin" ? `getHotelBooking` : `bookingall`;
      const { data } = await Api.get(`${bookingListUrl}/${uniqueId}`) //.then((res) => {
      console.log(data)
      const matchedData =
        data && data.find((object) => object.bookingId === bookingID)
      console.log(matchedData)

      if (matchedData !== undefined) {
        Api.get(
          `${baseurl}${fetchBookingIdDetailsUrl}/${uniqueId}/${bookingID}`
        ).then(({ data }) => {
          //filling billed by fields from the fetched data
          setBilledByDetails({
            businessName: data.businessName,
            billerContactNo: data.billerContactNo,
            billerEmail: data.billerEmail,
            shippingAddress: data.shippingAddress,
            panNo: data.panNo,
            billingDate: data.billingDate.split(" ")[0],
            state: data.businessState,
            pinCode: data.businessPincode,
          })

          //filling billed to (or) client fields from the fetched data
          setBilledToDetails({
            clientName: data.clientName,
            clientNumber: data.clientNumber,
            clientEmail: data.clientEmail,
            billingAddress: data.billingAddress,
            clientState: billedToDetails.clientState.toLowerCase(),
          })
        })
      } else {
        resetAll()
        toast.error("Invalid Booking ID")
      }
    } else {
      toast.error(`GST Invoice exists for Booking ID ${bookingID}`)
    }
  }

  const handleInputBlur = (event) => {
    const { name, value } = event.target
    validationSchema
      .validateAt(name, { [name]: value })
      .then(() => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }))
      })
      .catch((error) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: error.message,
        }))
      })
  }

  const [billingInputValues, setBillingInputValues] = useState([
    {
      id: "",
      bookingItem: "",
      code: "",
      rate: "",
      quantity: "",
      taxableAmt: "",
      cgst: "",
      sgst: "",
      igst: "",
      taxPercentValue: "0",
      amountIncGst: "",
    },
  ])

  const [totalTaxableAmount, setTotalTaxableAmount] = useState(null)
  const [totalTaxAmount, setTotalTaxAmount] = useState(null)
  const [totalAmountIncGst, setTotalAmountIncGst] = useState(null)

  const [billedByDetails, setBilledByDetails] = useState({
    id: "",
    businessName: "",
    panNo: "",
    billerContactNo: "",
    billerEmail: "",
    billingDate: null,
    dueDate: null,
    shippingAddress: "",
    pinCode: "",
    state: "",
  })

  const [billedToDetails, setBilledToDetails] = useState({
    id: "",
    clientName: "",
    clientNumber: "",
    clientEmail: "",
    gstNo: "",
    clientPanNo: "",
    billingAddress: "",
    clientPinCode: "",
    clientState: "",
  })

  const [termAndConditions, setTermsAndConditions] = useState("")
  const [addNotes, setAddNotes] = useState("")

  const resetAll = () => {
    setBilledByDetails({
      id: "",
      businessName: "",
      panNo: "",
      billerContactNo: "",
      billerEmail: "",
      billingDate: null,
      dueDate: null,
      shippingAddress: "",
      pinCode: "",
      state: "",
    })
    setBilledToDetails({
      id: "",
      clientName: "",
      clientNumber: "",
      clientEmail: "",
      gstNo: "",
      clientPanNo: "",
      billingAddress: "",
      clientPinCode: "",
      clientState: "",
    })
    setBillingInputValues([
      {
        id: "",
        bookingItem: "",
        code: "",
        rate: "",
        quantity: "",
        taxableAmt: "",
        cgst: "",
        sgst: "",
        igst: "",
        taxPercentValue: "0",
        amountIncGst: "",
      },
    ])
    setTotalAmountIncGst(null)
    setTotalTaxAmount(null)
    setTotalTaxableAmount(null)
    setTermsAndConditions("")
    setAddNotes("")
  }

  //adds new input fields in the billing info...
  const handleAddBillingInfo = () => {
    const updatedBilling = [
      ...billingInputValues,
      {
        id: "",
        bookingItem: "",
        code: "",
        rate: "",
        quantity: "",
        taxableAmt: "",
        cgst: "",
        sgst: "",
        igst: "",
        taxPercentValue: "",
        amountIncGst: "",
      },
    ]

    setBillingInputValues(updatedBilling)
  }

  //handleWheelScroll for changing of values in number type input field while scrolling mouseWheel
  const handleScroll = (e) => {
    e.target.blur()
  }

  //handleTermsAndConditions
  const handleTermsConditionsAndNotes = (e) => {
    const { value, name } = e.target
    console.log(`${name}: ${value}`)
    name === "addNotes" ? setAddNotes(value) : setTermsAndConditions(value)
  }

  //deletes the input fields targeting the index of the object
  const handleDeleteBillingInfo = (index) => {
    const updatedBilling = billingInputValues.filter(
      (value, idx) => idx !== index
    )

    const amountTaxableInTotal = updatedBilling.reduce((prev, curr) => {
      prev += parseFloat(curr.taxableAmt)
      return prev
    }, 0)

    const taxAmountInTotal = updatedBilling.reduce((prev, curr) => {
      prev += parseFloat(curr.taxableAmt) * parseFloat(curr.taxPercentValue)
      return prev
    }, 0)

    const totalAmountWithGst = updatedBilling.reduce((prev, curr) => {
      prev += parseFloat(curr.amountIncGst)
      return prev
    }, 0)

    setTotalTaxableAmount(amountTaxableInTotal)
    setTotalTaxAmount(taxAmountInTotal)
    setTotalAmountIncGst(totalAmountWithGst)
    setBillingInputValues(updatedBilling)
  }

  //handling BookingID change
  const handleChangeBookingID = (e) => {
    const { value } = e.target
    console.log("bookingId", value)
    setBookingID(value)
  }

  //handleChange biller input value
  const handleChangeBillerInputs = (e) => {
    const { value, name } = e.target
    console.log(`${name}`, value)
    setBilledByDetails({ ...billedByDetails, [name]: value })
  }

  //handling dueDate change
  const handleDueDateChange = (date) => {
    setBilledByDetails({
      ...billedByDetails,
      dueDate: format(date, "yyyy-MM-dd"),
    })
  }

  //handling billingDate change
  const handleBillingDateChange = (date) => {
    setBilledByDetails({
      ...billedByDetails,
      billingDate: format(date, "yyyy-MM-dd"),
    })
    console.log(format(date, "yyyy-MM-dd"))
  }

  //handleChange Client input value
  const handleChangeClientInputs = (e) => {
    const { value, name } = e.target
    console.log(`${name}`, value)
    setBilledToDetails({ ...billedToDetails, [name]: value })
  }

  //calculating function for billing info...
  const handleChangeTaxInputs = async (index, e, forTaxableAmount) => {
    console.log(forTaxableAmount, "now")
    const { name, value } = e.target
    console.log(index, name, value)

    const updatedBilling = billingInputValues.map((bill, idx) => {
      if (idx === index) {
        console.log()
        return {
          ...bill,
          [name]: value,
          taxableAmt:
            name === "quantity" || name === "rate"
              ? value * parseInt(forTaxableAmount)
              : bill.taxableAmt,

          taxPercentValue:
            name === "taxPercentValue" &&
            bill.rate &&
            bill.quantity &&
            bill.taxableAmt &&
            value,

          cgst:
            name === "taxPercentValue" &&
            bill.rate &&
            billedByDetails.state.toLowerCase() ===
              billedToDetails.clientState.toLowerCase() &&
            (parseFloat(forTaxableAmount) * value) / 2,
          sgst:
            name === "taxPercentValue" &&
            bill.rate &&
            billedByDetails.state.toLowerCase() ===
              billedToDetails.clientState.toLowerCase() &&
            (parseFloat(forTaxableAmount) * value) / 2,
          igst:
            name === "taxPercentValue" &&
            bill.rate &&
            billedByDetails.state.toLowerCase() !==
              billedToDetails.clientState.toLowerCase() &&
            parseFloat(forTaxableAmount) * value,
          amountIncGst:
            name === "taxPercentValue" &&
            bill.rate &&
            parseFloat(bill.rate) * parseFloat(bill.quantity) +
              parseFloat(bill.rate) * parseFloat(bill.quantity) * value,
        }
      }
      return bill
    })

    const amountTaxableInTotal = updatedBilling.reduce((prev, curr) => {
      prev += parseFloat(curr.taxableAmt)
      return prev
    }, 0)

    const taxAmountInTotal = updatedBilling.reduce((prev, curr) => {
      prev += parseFloat(curr.taxableAmt) * parseFloat(curr.taxPercentValue)
      return prev
    }, 0)

    const totalAmountWithGst = updatedBilling.reduce((prev, curr) => {
      prev += parseFloat(curr.amountIncGst)
      return prev
    }, 0)

    setTotalTaxableAmount(amountTaxableInTotal)
    setTotalTaxAmount(taxAmountInTotal)
    setTotalAmountIncGst(totalAmountWithGst)
    console.log(updatedBilling)
    setBillingInputValues(updatedBilling)
  }

  const handleSubmit = async (e) => {
    console.log("before", billingInputValues)
    e.preventDefault()
    const postData = {
      id: invoiceId ? id : "",
      uniqueId: uniqueId,
      bookingId: bookingID,
      invoiceNo: invoiceId ? invoiceId : "",
      totalTaxableAmount: totalTaxableAmount,
      totalTax: totalTaxAmount,
      totalAmountWithGst: totalAmountIncGst,
      termsAndCondition: termAndConditions,
      addNotes: addNotes,
      gstBilledBy: billedByDetails,
      gstBilledTo: billedToDetails,
      gstBillingInformation: billingInputValues,
      createdBy: createdByName,
      createdAt: "",
    }

    const submitUrlBasedOnRole =
      role === "Hotel Admin" ? "hotelgstdetails" : "gstdetails"

    validationSchema
      .validate(
        {
          ...billedByDetails,
          ...billedToDetails,
          bookingID,
          termAndConditions,
        },
        { abortEarly: false }
      )
      .then(() => {
        Api.post(`${baseurl}${submitUrlBasedOnRole}`, postData)
          .then((res) =>
            invoiceId
              ? toast.success(`GST Invoice Updated`)
              : toast.success(`GST Invoice Generated`)
          )
          .then(() => {
            history.replace("/konnect/invoiceList")
          })
      })
      .catch((errors) => {
        const validationErrors = {}
        errors.inner.forEach((error) => {
          validationErrors[error.path] = error.message
        })
        console.log(validationErrors)
        setErrors(validationErrors)
      })
  }

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {width < 768 ? (
          <GenerateInvoiceMobileUI
            billedByDetails={billedByDetails}
            billedToDetails={billedToDetails}
            errors={errors}
            handleInputBlur={handleInputBlur}
            handleChangeBillerInputs={handleChangeBillerInputs}
            setBilledByDetails={setBilledByDetails}
            handleBillingDateChange={handleBillingDateChange}
            handleDueDateChange={handleDueDateChange}
            handleScroll={handleScroll}
            setBilledToDetails={setBilledToDetails}
            handleChangeClientInputs={handleChangeClientInputs}
            billingInputValues={billingInputValues}
            handleDeleteBillingInfo={handleDeleteBillingInfo}
            handleAddBillingInfo={handleAddBillingInfo}
            handleChangeTaxInputs={handleChangeTaxInputs}
            taxOptions={taxOptions}
            totalTaxableAmount={totalTaxableAmount}
            totalTaxAmount={totalTaxAmount}
            totalAmountIncGst={totalAmountIncGst}
            termAndConditions={termAndConditions}
            addNotes={addNotes}
            handleTermsConditionsAndNotes={handleTermsConditionsAndNotes}
            handleSubmit={handleSubmit}
            bookingID={bookingID}
            handleChangeBookingID={handleChangeBookingID}
            handleSearch={handleSearch}
            invoiceId={invoiceId}
          />
        ) : (
          <>
            <div className="generateInvoice">
              <h2
                className={classes.title}
                style={{
                  fontSize: "30px",
                }}
              >
                Generate Invoice
              </h2>
              <Box bgcolor="#eaeaea" className={classes.insidepaper}>
                <TextField
                  name="bookingId"
                  onChange={handleChangeBookingID}
                  value={bookingID}
                  type="search"
                  variant="outlined"
                  InputProps={{
                    style: {
                      height: "40px",
                      borderRadius: "10px 0px 0px 10px",
                    },
                  }}
                  placeholder="Booking ID"
                  onBlur={handleInputBlur}
                  error={Boolean(errors.bookingId)}
                  helperText={errors.bookingId}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                />
                <Button
                  style={{
                    height: "40px",
                    position: "absolute",
                    marginLeft: "-10px",
                    width: "150px",
                    borderRadius: "10px",
                    fontSize: "40px",
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Box>
            </div>
            <div className="invoiceBasicInfo">
              <h2 className={classes.titles}>Basic Information</h2>
              <div
                className="basicInfoContainer"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginLeft: "-5px",
                }}
              >
                <Box
                  className={classes.insidepaper}
                  style={{ width: "47%", marginLeft: "-1%" }}
                >
                  <h3
                    className={classes.title}
                    style={{ fontWeight: "normal", marginBottom: "15px" }}
                  >
                    Billed By
                  </h3>
                  <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        name="businessName"
                        value={billedByDetails.businessName}
                        label="Business Name"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginRight: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeBillerInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.businessName)}
                        helperText={errors.businessName}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />

                      <TextField
                        name="panNo"
                        value={billedByDetails.panNo.toUpperCase()}
                        label="PAN No"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginLeft: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeBillerInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.panNo)}
                        helperText={errors.panNo}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <MuiPhoneNumber
                        name="billerContactNo"
                        value={billedByDetails.billerContactNo}
                        label="Contact Number"
                        type="tel"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginRight: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={(value) =>
                          setBilledByDetails({
                            ...billedByDetails,
                            billerContactNo: value,
                          })
                        }
                        onBlur={handleInputBlur}
                        error={Boolean(errors.billerContactNo)}
                        helperText={errors.billerContactNo}
                        defaultCountry="in"
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                          style: { marginLeft: "0%", marginTop: "0%" },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 0px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />

                      <TextField
                        name="billerEmail"
                        value={billedByDetails.billerEmail}
                        label="Email ID"
                        type="email"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginLeft: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeBillerInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.billerEmail)}
                        helperText={errors.billerEmail}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          position: "relative",
                          width: "50%",
                          marginRight: "2px",
                        }}
                      >
                        <DatePicker
                          name="billingDate"
                          value={billedByDetails.billingDate}
                          required
                          label="Billing Date"
                          inputVariant="outlined"
                          fullWidth
                          size="small"
                          animateYearScrolling
                          format="dd/MM/yyyy"
                          variant="inline"
                          disablePast="true"
                          autoOk="true"
                          onChange={handleBillingDateChange}
                          onBlur={handleInputBlur}
                          error={Boolean(errors.billingDate)}
                          helperText={errors.billingDate}
                          FormHelperTextProps={{
                            classes: { root: classes.helperText },
                          }}
                          InputLabelProps={{
                            style: {
                              backgroundColor: "white",
                              color: "#f46d25",
                              padding: "0px 5px 5px 5px",
                              borderRadius: "5px",
                            },
                            shrink: true,
                          }}
                        />
                        <DateRangeIcon className={classes.icon} />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "50%",
                          marginLeft: "2px",
                        }}
                      >
                        <DatePicker
                          name="dueDate"
                          value={billedByDetails.dueDate}
                          required
                          label="Due Date"
                          inputVariant="outlined"
                          fullWidth
                          size="small"
                          animateYearScrolling
                          format="dd/MM/yyyy"
                          variant="inline"
                          disablePast="true"
                          autoOk="true"
                          onChange={handleDueDateChange}
                          InputLabelProps={{
                            style: {
                              backgroundColor: "white",
                              color: "#f46d25",
                              padding: "0px 5px 5px 5px",
                              borderRadius: "5px",
                            },
                            shrink: true,
                          }}
                        />
                        <DateRangeIcon className={classes.icon} />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <TextField
                      name="shippingAddress"
                      value={billedByDetails.shippingAddress}
                      label="Shipping Address"
                      type="text"
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", alignSelf: "flex-start" }}
                      InputProps={{ style: { height: "40px" } }}
                      onChange={handleChangeBillerInputs}
                      onBlur={handleInputBlur}
                      error={Boolean(errors.shippingAddress)}
                      helperText={errors.shippingAddress}
                      FormHelperTextProps={{
                        classes: { root: classes.helperText },
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                          color: "#f46d25",
                          padding: "0px 5px 5px 5px",
                          borderRadius: "5px",
                        },
                        shrink: true,
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div
                      style={{ display: "flex", backgroundColor: "#eaeaea" }}
                    >
                      <TextField
                        name="pinCode"
                        value={billedByDetails.pinCode}
                        label="Pincode"
                        type="number"
                        onWheel={handleScroll}
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginRight: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeBillerInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.pinCode)}
                        helperText={errors.pinCode}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />

                      <TextField
                        name="state"
                        value={billedByDetails.state}
                        label="State"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginLeft: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeBillerInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.state)}
                        helperText={errors.state}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </Box>
                <Box
                  className={classes.insidepaper}
                  style={{ width: "48%", marginRight: "-0.6%" }}
                >
                  <h3
                    className={classes.title}
                    style={{ fontWeight: "normal", marginBottom: "15px" }}
                  >
                    Billed To
                  </h3>
                  <div style={{ marginBottom: "10px" }}>
                    <TextField
                      name="clientName"
                      value={billedToDetails.clientName}
                      label="Client Name"
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", alignSelf: "flex-start" }}
                      InputProps={{ style: { height: "40px" } }}
                      onChange={handleChangeClientInputs}
                      onBlur={handleInputBlur}
                      error={Boolean(errors.clientName)}
                      helperText={errors.clientName}
                      FormHelperTextProps={{
                        classes: { root: classes.helperText },
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                          color: "#f46d25",
                          padding: "0px 5px 5px 5px",
                          borderRadius: "5px",
                        },
                        shrink: true,
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <MuiPhoneNumber
                        name="clientNumber"
                        value={billedToDetails.clientNumber}
                        label="Contact Number"
                        type="tel"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginRight: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={(value) =>
                          setBilledToDetails({
                            ...billedToDetails,
                            clientNumber: value,
                          })
                        }
                        defaultCountry="in"
                        onBlur={handleInputBlur}
                        error={Boolean(errors.clientNumber)}
                        helperText={errors.clientNumber}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                          style: { marginLeft: "0%", marginTop: "0%" },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 0px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />

                      <TextField
                        name="clientEmail"
                        value={billedToDetails.clientEmail}
                        label=" Client Email ID"
                        type="email"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginLeft: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeClientInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.clientEmail)}
                        helperText={errors.clientEmail}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        name="gstNo"
                        value={billedToDetails.gstNo}
                        label="GSTN"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginRight: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeClientInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.gstNo)}
                        helperText={errors.gstNo}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />

                      <TextField
                        name="clientPanNo"
                        value={billedToDetails.clientPanNo}
                        label=" PAN No"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginLeft: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeClientInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.clientPanNo)}
                        helperText={errors.clientPanNo}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: "10px" }}>
                    <TextField
                      name="billingAddress"
                      value={billedToDetails.billingAddress}
                      label="Billing Address"
                      type="text"
                      variant="outlined"
                      size="small"
                      style={{ width: "100%", alignSelf: "flex-start" }}
                      InputProps={{ style: { height: "40px" } }}
                      onChange={handleChangeClientInputs}
                      onBlur={handleInputBlur}
                      error={Boolean(errors.billingAddress)}
                      helperText={errors.billingAddress}
                      FormHelperTextProps={{
                        classes: { root: classes.helperText },
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                          color: "#f46d25",
                          padding: "0px 5px 5px 5px",
                          borderRadius: "5px",
                        },
                        shrink: true,
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <div style={{ display: "flex" }}>
                      <TextField
                        name="clientPinCode"
                        value={billedToDetails.clientPinCode}
                        label="Pincode"
                        onWheel={handleScroll}
                        type="number"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginRight: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeClientInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.clientPinCode)}
                        helperText={errors.clientPinCode}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />

                      <TextField
                        name="clientState"
                        value={billedToDetails.clientState}
                        label="State"
                        type="text"
                        variant="outlined"
                        size="small"
                        style={{
                          width: "50%",
                          marginLeft: "2px",
                          alignSelf: "flex-start",
                        }}
                        InputProps={{ style: { height: "40px" } }}
                        onChange={handleChangeClientInputs}
                        onBlur={handleInputBlur}
                        error={Boolean(errors.clientState)}
                        helperText={errors.clientState}
                        FormHelperTextProps={{
                          classes: { root: classes.helperText },
                        }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                </Box>
              </div>
            </div>
            <div className="billingInfo">
              <h2 className={classes.titles}> Billing Information</h2>
              <Box
                className={classes.insidepaper}
                style={{
                  width: "98.4%",
                  marginLeft: "-6px",
                  height: "max-content",
                }}
              >
                <>
                  {billingInputValues &&
                    billingInputValues.map((bill, i) => (
                      <div style={{ display: "flex", marginBottom: "15px" }}>
                        <span style={{ margin: "10px 10px 0px 0px" }}>
                          {i + 1}.
                        </span>
                        <Grid container spacing={1}>
                          <Grid item xs={3}>
                            <TextField
                              name="bookingItem"
                              type="text"
                              label="Item Description"
                              value={bill.bookingItem}
                              InputProps={{ style: { height: "40px" } }}
                              fullWidth
                              variant="outlined"
                              onChange={(e) => handleChangeTaxInputs(i, e)}
                              InputLabelProps={{
                                style: {
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                  padding: "0px 5px 5px 5px",
                                  borderRadius: "5px",
                                },
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              name="code"
                              label="HSN/SASC Code"
                              value={bill.code}
                              InputProps={{ style: { height: "40px" } }}
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                style: {
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                  padding: "0px 5px 5px 5px",
                                  borderRadius: "5px",
                                  fontSize: "13px",
                                },
                                shrink: true,
                              }}
                              onChange={(e) => handleChangeTaxInputs(i, e)}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              name="rate"
                              label="Rate"
                              value={bill.rate}
                              type="number"
                              onWheel={handleScroll}
                              InputProps={{ style: { height: "40px" } }}
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChangeTaxInputs(i, e, bill.quantity)
                              }
                              InputLabelProps={{
                                style: {
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                  padding: "0px 5px 5px 5px",
                                  borderRadius: "5px",
                                },
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              name="quantity"
                              label="Quantity"
                              value={bill.quantity}
                              type="number"
                              onWheel={handleScroll}
                              InputProps={{ style: { height: "40px" } }}
                              fullWidth
                              variant="outlined"
                              onChange={(e) =>
                                handleChangeTaxInputs(i, e, bill.rate)
                              }
                              InputLabelProps={{
                                style: {
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                  padding: "0px 5px 5px 5px",
                                  borderRadius: "5px",
                                },
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={2.8}>
                            <TextField
                              name="taxableAmt"
                              label="Taxable Amount"
                              type="number"
                              onWheel={handleScroll}
                              value={bill.taxableAmt}
                              InputProps={{ style: { height: "40px" } }}
                              fullWidth
                              variant="outlined"
                              disabled
                              InputLabelProps={{
                                style: {
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                  padding: "0px 5px 5px 5px",
                                  borderRadius: "5px",
                                },
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                              style={{ marginRight: "15px", width: "100%" }}
                            >
                              <InputLabel
                                shrink
                                style={{
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                  padding: "0px 5px 5px 5px",
                                  borderRadius: "5px",
                                }}
                              >
                                Tax %
                              </InputLabel>
                              <Select
                                name="taxPercentValue"
                                value={bill.taxPercentValue}
                                label="Tax %"
                                style={{
                                  height: "40px",
                                  width: "100%",
                                  fontSize: "13px",
                                }}
                                onChange={(e) =>
                                  handleChangeTaxInputs(i, e, bill.taxableAmt)
                                }
                              >
                                {taxOptions.map((tax) => (
                                  <MenuItem
                                    value={tax.value}
                                    style={{ fontSize: "13px" }}
                                  >
                                    {tax.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          {billedByDetails.state.toLowerCase() !==
                          billedToDetails.clientState.toLowerCase() ? (
                            <Grid item xs={2}>
                              <TextField
                                name="igst"
                                label="IGST"
                                value={bill.igst}
                                type="number"
                                disabled
                                onWheel={handleScroll}
                                InputProps={{ style: { height: "40px" } }}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                  style: {
                                    backgroundColor: "white",
                                    color: "#f46d25",
                                    padding: "0px 5px 5px 5px",
                                    borderRadius: "5px",
                                  },
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          ) : (
                            <>
                              <Grid item xs={2}>
                                <TextField
                                  name="cgst"
                                  label="CGST"
                                  value={bill.cgst}
                                  type="number"
                                  onWheel={handleScroll}
                                  InputProps={{ style: { height: "40px" } }}
                                  fullWidth
                                  variant="outlined"
                                  disabled
                                  InputLabelProps={{
                                    style: {
                                      backgroundColor: "white",
                                      color: "#f46d25",
                                      padding: "0px 5px 5px 5px",
                                      borderRadius: "5px",
                                    },
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  name="sgst"
                                  label="SGST"
                                  value={bill.sgst}
                                  type="number"
                                  disabled
                                  onWheel={handleScroll}
                                  InputProps={{ style: { height: "40px" } }}
                                  fullWidth
                                  variant="outlined"
                                  InputLabelProps={{
                                    style: {
                                      backgroundColor: "white",
                                      color: "#f46d25",
                                      padding: "0px 5px 5px 5px",
                                      borderRadius: "5px",
                                    },
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                            </>
                          )}

                          <Grid item xs={2}>
                            <TextField
                              name="amountIncGst"
                              label="Amount (Inc. GST)"
                              value={bill.amountIncGst}
                              disabled
                              type="number"
                              InputProps={{ style: { height: "40px" } }}
                              fullWidth
                              variant="outlined"
                              InputLabelProps={{
                                style: {
                                  backgroundColor: "white",
                                  color: "#f46d25",
                                  padding: "0px 5px 5px 5px",
                                  borderRadius: "5px",
                                },
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                        <div style={{ display: "flex" }}>
                          <>
                            {billingInputValues.length !== 1 && (
                              <DeleteIcon
                                className={classes.plus}
                                onClick={() => handleDeleteBillingInfo(i)}
                              />
                            )}
                            {billingInputValues.length - 1 === i && (
                              <AddCircleOutlineIcon
                                onClick={handleAddBillingInfo}
                                size="small"
                                className={classes.plus}
                              />
                            )}
                          </>
                        </div>
                      </div>
                    ))}
                </>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "30px",
                    marginTop: "40px",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      width: "30%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Total Taxable Amount"
                      disabled
                      style={{ width: "50%", font: "#f46d25" }}
                      InputProps={{
                        style: {
                          height: "40px",
                          width: "100%",
                          background: "white",
                        },
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          marginTop: "-8px",
                          fontWeight: "bold",
                          color: "#f46d25",
                        },
                      }}
                    />
                    <TextField
                      name="totalTaxableAmount"
                      value={totalTaxableAmount && totalTaxableAmount}
                      disabled
                      variant="outlined"
                      type="number"
                      onWheel={handleScroll}
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        width: "50%",
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                          position: "absolute",
                          width: "100%",
                          background: "rgba(0, 0, 0, 0.1)",
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              marginLeft: "10px",
                              color: "solid black",
                              fontWeight: "Bold",
                            }}
                          >
                            ₹
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      width: "30%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Total Tax"
                      disabled
                      style={{ width: "50%", font: "#f46d25" }}
                      InputProps={{
                        style: {
                          height: "40px",
                          width: "100%",
                          position: "absolute",
                          background: "white",
                        },
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          marginTop: "-8px",
                          fontWeight: "bold",
                          color: "#f46d25",
                        },
                      }}
                    />
                    <TextField
                      name="totalTaxAmount"
                      value={totalTaxAmount && totalTaxAmount}
                      type="number"
                      onWheel={handleScroll}
                      disabled
                      variant="outlined"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        width: "50%",
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                          width: "100%",
                          background: "rgba(244, 109, 37, 0.1)",
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              marginLeft: "10px",
                              color: "solid black",
                              fontWeight: "Bold",
                            }}
                          >
                            ₹
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      width: "30%",
                    }}
                  >
                    <TextField
                      variant="outlined"
                      label="Total Amount With GST"
                      disabled
                      style={{ width: "50%", font: "#f46d25" }}
                      InputProps={{
                        style: {
                          height: "40px",
                          width: "100%",
                          position: "absolute",
                          background: "white",
                        },
                      }}
                      InputLabelProps={{
                        shrink: false,
                        style: {
                          marginTop: "-8px",
                          fontWeight: "bold",
                          color: "#f46d25",
                        },
                      }}
                    />
                    <TextField
                      name="totalAmountIncGst"
                      value={totalAmountIncGst && totalAmountIncGst}
                      type="number"
                      onWheel={handleScroll}
                      disabled
                      variant="outlined"
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.1)",
                        width: "50%",
                      }}
                      InputProps={{
                        style: {
                          height: "40px",
                          width: "100%",
                          background: "rgba(244, 109, 37, 0.1)",
                        },
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{
                              marginLeft: "10px",
                              color: "solid black",
                              fontWeight: "Bold",
                            }}
                          >
                            ₹
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </Box>
              <div className="termsAndConditionsAndNotes">
                <h2 className={classes.titles}>Terms & Conditions</h2>
                <Box
                  className={classes.insidepaper}
                  style={{
                    width: "98.4%",
                    marginLeft: "-1px",
                    marginRight: "-10px",
                    height: "max-content",
                    paddingBottom: "30px",
                  }}
                >
                  <TextField
                    name="termsAndConditions"
                    value={termAndConditions}
                    onChange={handleTermsConditionsAndNotes}
                    label="T&C"
                    placeholder="Terms & Conditions"
                    variant="outlined"
                    style={{ width: "100%" }}
                    InputProps={{
                      style: { height: "80px", paddingTop: "40px" },
                    }}
                    multiline
                    rows={4}
                    InputLabelProps={{
                      style: {
                        backgroundColor: "white",
                        color: "#f46d25",
                        padding: "0px 5px 5px 5px",
                        borderRadius: "5px",
                      },
                      shrink: true,
                    }}
                  />
                  <TextField
                    name="addNotes"
                    value={addNotes}
                    onChange={handleTermsConditionsAndNotes}
                    label="Notes"
                    placeholder="Add Notes"
                    variant="outlined"
                    InputProps={{ style: { height: "60px" } }}
                    style={{
                      width: "100%",
                      marginTop: "20px",
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: "white",
                        color: "#f46d25",
                        padding: "0px 5px 5px 5px",
                        borderRadius: "5px",
                      },
                      shrink: true,
                    }}
                    multiline
                    rows={2}
                  />
                </Box>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <Button
                type="submit"
                style={{
                  background: "#f46d25",
                  color: "white",
                  width: "15%",
                }}
                onClick={handleSubmit}
              >
                {invoiceId ? "Update Invoice" : "Generate GST"}
              </Button>
            </div>
          </>
        )}
      </MuiPickersUtilsProvider>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    // padding: "100px 0px",
    // margin: "0px 30px",

    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      margin: "0px 10px",
    },
  },
  paper: {
    padding: "30px 40px",
    marginLeft: "20px",
    marginRight: "20px",

    "@media (max-width: 767px)": {
      padding: "10px",
    },
  },

  selectIcon: {
    fill: "#f46d25",
  },
  insidepaper: {
    boxShadow: "0px 1px 4px 1px rgba(52, 58, 64, 0.1)",
    borderRadius: "6px",
    marginTop: "2px",
    backgroundColor: "#eaeaea",
    padding: "10px",
    width: "98%",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  plusmobile: {
    cursor: "pointer",
    color: "#f46d25",
    top: "7px",
    position: "relative",
  },
  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#f46d25",
    margin: "0px",
  },
  titles: {
    padding: "10px",
    fontSize: "28px",
    fontWeight: "lighter",
    color: "#fff",
    background: "#f46d25",
    margin: "20px 10px 10px 0px",
    borderRadius: "8px 8px 0px 0px",
  },
  formControl: {
    minWidth: "60px",
    height: "40px",
    background: "white",
  },
  helperText: {
    backgroundColor: "#eaeaea",
    padding: "0",
    margin: "0",
    width: "100%",
    height: "100%",
  },
}))

export default GenerateInvoice
