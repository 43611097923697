import styles from "./DestinationHome.styles.module.css"
import "./slider.styles.css"

import DestinationCardV2 from "../../../../components/DestinationNew/DestinationCard_V2/DestinationCardV2.component"

import sample from "../../../../assets/illustrations/hotels_mp/Golden Tulip.png"
import sample2 from "../../../../assets/illustrations/hotels_mp/Giri Vihar.png"

import NextTripBanner from "../../../../assets/illustrations/destination/destination_intermediate_banner/background_next_trip.png"
import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { ContactModalContext } from "../../../../context/contactModal.context"

import Badrinath from "../../../../assets/illustrations/destination/pilgrims_card/badrinath.png"
import Rishikesh from "../../../../assets/illustrations/destination/pilgrims_card/rishikesh.png"
import Haridwar from "../../../../assets/illustrations/destination/pilgrims_card/haridwar.png"
import Chardham from "../../../../assets/illustrations/destination/pilgrims_card/chardham.png"
import PlanningNextTripBanner from "../../../../components/DestinationNew/PlanningNextTripBanner/PlanningNextTripBanner.component"

import KeyfactsBackground from "../../../../assets/illustrations/destination/banner_images/destination_bg_1.png"
import { Link, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import Slider from "react-slick"

import SlideImage1 from "../../../../assets/illustrations/destination/slider-images/Haridwar2.jpg"
import SlideImage2 from "../../../../assets/illustrations/destination/slider-images/Monsoon in Uttarakhand.jpg"
import SlideImage3 from "../../../../assets/illustrations/destination/slider-images/Nainital1.jpg"
import SlideImage4 from "../../../../assets/illustrations/destination/slider-images/Paragliding.jpg"
import SlideImage5 from "../../../../assets/illustrations/destination/slider-images/Rishikesh1.jpg"
import SlideImage6 from "../../../../assets/illustrations/destination/slider-images/Rishikesh2.jpg"
import SlideImage7 from "../../../../assets/illustrations/destination/slider-images/Tungnath-Temple-by-Abhinav-Tunait-www.flickr.comphotos103602929@N08.jpg"
import SlideImage8 from "../../../../assets/illustrations/destination/slider-images/rafting.jpg"

import DehradunImg from "../../../../assets/illustrations/destination/where-you-headed/Dehradun.jpg"
import HaridwarImg from "../../../../assets/illustrations/destination/where-you-headed/Haridwar.jpg"
import MussoorieImg from "../../../../assets/illustrations/destination/where-you-headed/Mussoorie.jpg"
import RishikeshImg from "../../../../assets/illustrations/destination/where-you-headed/Rishikesh2.jpg"
import TungnathTempleImg from "../../../../assets/illustrations/destination/where-you-headed/Tungnath-Temple-by-Abhinav-Tunait-www.flickr.comphotos103602929@N08.jpg"
import UkhimathImg from "../../../../assets/illustrations/destination/where-you-headed/Ukhimath.jpg"
import AuliImg from "../../../../assets/illustrations/destination/where-you-headed/auli.jpg"
import NainitalImg from "../../../../assets/illustrations/destination/where-you-headed/nanital.jpg"
import DhanaultiImg from "../../../../assets/illustrations/destination/where-you-headed/Dhanaulti_2.jpg"
import ChamoliImg from "../../../../assets/illustrations/destination/where-you-headed/valley-of-flowers-5353172.jpg"
import MunsyariImg from "../../../../assets/illustrations/destination/where-you-headed/Chopta Chandrashila Trek3.JPG"

import BungeeJumping from "../../../../assets/illustrations/destination/things_todo/Bungee jumping in Rishikesh5.jpg"
import KrmptyFalls from "../../../../assets/illustrations/destination/things_todo/Visit To Kempty Falls in Mussoorie1.jpg"
import RajajiNationalPark from "../../../../assets/illustrations/destination/things_todo/Visit to Rajaji National Park2.jpg"
import ValleyOfFlowers from "../../../../assets/illustrations/destination/things_todo/Visit to the Valley of Flowers National Park3.jpg"
import { IconButton } from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

import KumbhMela from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/kumbh mela.jpg"
import Festival2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/Copy of Events & Festivals in Uttarakhand.jpeg"
import Festival3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/Tehri Lake Festival.jpg"
import Festival4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/International Yoga Festival.jpg"
import LaunchIcon from "@material-ui/icons/Launch"
import {
  handleOpenVideoPlayerModal,
  setVideoUrl,
} from "../../../../../redux/actions/videoPlayerModal.action"
import { useDispatch } from "react-redux"
import ReactPlayer from "react-player/lazy"
import { ReactComponent as YoutubeIcon } from "../../../../assets/icons/youtube-custom.svg"

const Keyfacts = [
  {
    id: "",
    content:
      "A key fact about Uttarakhand is that it is spread over a sprawling area of 53,483 km2. Another unique fact about Uttarakhand is that about 86% of the state is covered with mountains and approximately 65% of it is covered by forest. The northern part of the state is distinguished by Himalayan peaks and prominent glaciers.",
  },
  {
    id: "",
    content:
      "Uttarakhand is that about 86% of the state is covered with mountains and approximately 65% of it is covered by forest",
  },
  {
    id: "",
    content:
      "Nanda Devi and Valley of Flowers National Park located in the western Himalayas has been recognized by UNESCO as a world heritage site. It has unmatched natural beauty and has both historical as well as geographical significance.",
  },
  {
    id: "",
    content:
      "Another Uttarakhand quick fact is that it has a population of 10,086,292, which makes it the 20th most populous state in India",
  },
  {
    id: "",
    content: "189 people per km2",
  },
  {
    id: "",
    content:
      "The majority of Uttarakhand's residents are Hindus. Other dominant religions are Muslims, Sikhs, Christians, Buddhists, and Jains.",
  },
  {
    id: "",
    content:
      "The best time to visit the hill stations is the summer. Winter is also an excellent time to get closer to snow-capped mountains and get a taste of winter sports.",
  },
  {
    id: "",
    content:
      "The official language is Hindi, while Garhwali, Kumaoni, and Jaunsari are other regional dialects. A hidden fact about Uttarakhand is that it is the only state in India that has Sanskrit as its second official language after Hindi.",
  },
]

const sliderImages = [
  { src: SlideImage1 },
  { src: SlideImage2 },
  { src: SlideImage3 },
  { src: SlideImage4 },
  { src: SlideImage5 },
  { src: SlideImage6 },
  { src: SlideImage7 },
  { src: SlideImage8 },
]

const whereYouHeadedSrc = [
  {
    src: HaridwarImg,
    name: "Haridwar",
    link: "haridwar",
    videoUrl: "https://www.youtube.com/watch?v=29JUdDsXCtY",
  },
  // {
  //   src: DehradunImg,
  //   name: "Dehradun",
  //   link: "Dehradun",
  //   videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  // },
  // {
  //   src: MussoorieImg,
  //   name: "Mussoorie",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  // },
  {
    src: RishikeshImg,
    name: "Rishikesh",
    link: "haridwar",
    videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
  },
  // {
  //   src: TungnathTempleImg,
  //   name: "Tungnath Temple",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
  // },
  // {
  //   src: UkhimathImg,
  //   name: "Ukhimath",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
  // },
  // {
  //   src: AuliImg,
  //   name: "Auli",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  // },
  {
    src: NainitalImg,
    name: "Nainital",
    link: "haridwar",
    videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  },
]

const hillStationsSrc = [
  { src: MussoorieImg, name: "Mussoorie", link: "haridwar" },
  { src: AuliImg, name: "Auli", link: "haridwar" },
  { src: NainitalImg, name: "Nainital", link: "haridwar" },
  { src: DhanaultiImg, name: "Dhanaulti", link: "haridwar" },
  { src: ChamoliImg, name: "Chamoli", link: "haridwar" },
  { src: MunsyariImg, name: "Munsyari", link: "haridwar" },
  { src: MussoorieImg, name: "Mussoorie", link: "haridwar" },
  { src: AuliImg, name: "Auli", link: "haridwar" },
]

const thingsTodoSource = [
  {
    src: BungeeJumping,
    name: "Bungee Jumping",
    link: "haridwar",
    subText: "Rishikesh",
  },
  {
    src: KrmptyFalls,
    name: "Kempty Falls",
    link: "haridwar",
    subText: "Mussoorie",
  },
  {
    src: RajajiNationalPark,
    name: "Rajaji National Park",
    link: "haridwar",
    subText: "Shiwalik Ranges",
  },
  {
    src: ValleyOfFlowers,
    name: "Valley Of Flowers",
    link: "haridwar",
    subText: "Chamoli",
  },
]

const festivalsAndEvents = [
  {
    src: KumbhMela,
    name: "Bungee Jumping",
    link: "haridwar",
    subText: "Rishikesh",
  },
  {
    src: Festival2,
    name: "Event In Uttarakhand",
    link: "haridwar",
    subText: "Mussoorie",
  },
  {
    src: Festival3,
    name: "Tehri Lake Festival",
    link: "haridwar",
    subText: "Shiwalik Ranges",
  },
  {
    src: Festival4,
    name: "International Yoga Festival",
    link: "haridwar",
    subText: "Chamoli",
  },
]

const NavigateButton = (props) => {
  return (
    <IconButton {...props} style={{ padding: "0rem" }}>
      <LaunchIcon style={{ color: "#fff" }} />
    </IconButton>
  )
}

const DestinationHome = () => {
  const { path } = useRouteMatch()
  // console.log({ path })
  const dispatch = useDispatch()
  const { setBanner } = useContext(DestinationLayoutContext)

  let sliderRef = useRef(null)

  const play = () => {
    sliderRef.slickPlay()
  }
  const pause = () => {
    sliderRef.slickPause()
  }

  useLayoutEffect(() => {
    setBanner("home")
  }, [])

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
  }

  const [pilgrimsCardContent, setPilgrimsCardContent] = useState([
    // {
    //   header: "Badrinath",
    //   imageSrc: Badrinath,
    //   videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
    // },
    {
      autoPlay: true,
      header: "Rishikesh",
      imageSrc: Rishikesh,
      videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
    },
    {
      autoPlay: true,
      header: "Haridwar",
      imageSrc: Haridwar,
      videoUrl: "https://www.youtube.com/watch?v=29JUdDsXCtY",
    },
    {
      autoPlay: true,
      header: "Chardham",
      imageSrc: Chardham,
      videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
    },
  ])

  const handleAutoPlay = (idx) => {
    let updatedPilgrimList = pilgrimsCardContent.map((p, i) => {
      if (idx == i) {
        return { ...p, autoPlay: true }
      }
      return p
    })

    setPilgrimsCardContent(updatedPilgrimList)
  }

  return (
    <>
      <section className={styles.destinationPageContainer}>
        <article className={styles.contentWithCarousel}>
          <div className={styles.contentCard}>
            <header className={styles.topSectionHeader}>
              Explore The <span>Unexplored</span>
            </header>
            <div>
              From a flavour of culture to a dash of adventure, Uttarakhand
              tourism offers something for everyone. A truly diverse state in
              the north of India, whether you are looking to ignite your
              spiritual fire, meander in its lush green meadows or explore the
              indigenous wildlife, Uttarakhand is the perfect destination for
              you.
            </div>
          </div>

          <div className="slider-container">
            <Slider
              {...settings}
              className="slider"
              ref={(slider) => (sliderRef = slider)}
            >
              {sliderImages.map((image, idx) => (
                <div className="slider-img-container" key={idx}>
                  <img {...image} />
                </div>
              ))}
            </Slider>
          </div>
        </article>
      </section>

      <section className={styles.whereYouHeaded}>
        <header onClick={() => window.open(`${path}/destination`, "_blank")}>
          Where You Headed In <span>Uttarakhand</span>{" "}
        </header>
        <article className={styles.whereYouHeadedCardContainer}>
          {whereYouHeadedSrc.map((location, idx) => (
            <DestinationCardV2
              className={styles.whereYouHeadedCard}
              key={idx}
              onClick={() => {
                dispatch(handleOpenVideoPlayerModal())
                dispatch(setVideoUrl(location.videoUrl))
              }}
            >
              <DestinationCardV2.Image src={location.src} />
              <DestinationCardV2.Footer>
                {location.name}
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
          {/* <IconButton
            color="primary"
            className={styles.navigatorArrow}
            onClick={() => window.open("/uttarakhand/destination", "_blank")}
          >
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </IconButton> */}
        </article>
      </section>

      <section className={styles.hillStationContainer}>
        <header className={styles.hillStationHeader}>
          Hills Stations in <span>Uttarakhand</span>
        </header>
        <div className={styles.hillStationDescription}>
          Uttarakhand, a region famed for its breathtaking hill stations that
          have everything needed for the ideal touching destinations, is dotted
          with lengthy mountain ranges and sparkling gems that grace the globe
          map. Wonderful views of the snow-capped Himalayas, the surrounding
          mountains, the meandering slopes, the pure natural appeal, and the
          uphill routes bordered by thick flora.
        </div>

        <div className={styles.hillStationCardList}>
          {hillStationsSrc.map((hill, idx) => (
            <DestinationCardV2 className={styles.hillStationCard} key={idx}>
              <DestinationCardV2.Image src={hill.src} />
              <DestinationCardV2.Footer>{hill.name}</DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
        </div>
      </section>

      {/* <section className={styles.thingsTodoContainer}>
        <header onClick={() => window.open("/uttarakhand/tourism", "_blank")}>
          Tourism In <span>Uttarakhand</span>{" "}
        </header>
        <div
          className={styles.pilgrimsDescription}
          style={{ textAlign: "left", padding: "0rem" }}
        >
          From a flavour of culture to a dash of adventure, Uttarakhand tourism
          offers something for everyone. A truly diverse state in the north of
          India, whether you are looking to ignite your spiritual fire, meander
          in its lush green meadows or explore the indigenous wildlife,
          Uttarakhand is the perfect destination for you.
        </div>
        <article className={styles.thingsTodoCardContainer}>
          {thingsTodoSource.map((things, idx) => (
            <DestinationCardV2 className={styles.thingsTodoCard} key={idx}>
              <DestinationCardV2.Image src={things.src} />
              <DestinationCardV2.Footer>
                {things.name} <p>{things.subText}</p>
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
    
        </article>
      </section> */}

      <section className={styles.thingsTodoContainer}>
        <header
          onClick={() => window.open("/uttarakhand/thingsToDo", "_blank")}
        >
          Things To Do In <span>Uttarakhand</span>{" "}
        </header>
        <div
          className={styles.pilgrimsDescription}
          style={{ textAlign: "left", padding: "0rem" }}
        >
          Uttarakhand, whose historic temples grace misty alpine views, provides
          pilgrims with heavenly haven amidst the majesty and peace of the
          antural world. Every step you take echoes with the sounds of
          dedication and reverence, in Haridwar to teh hallowed sancturies of
          Char Dham.
        </div>
        <article className={styles.thingsTodoCardContainer}>
          {thingsTodoSource.map((things, idx) => (
            <DestinationCardV2 className={styles.thingsTodoCard} key={idx}>
              <DestinationCardV2.Image src={things.src} />
              <DestinationCardV2.Footer>
                {things.name} <p>{things.subText}</p>
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
          {/* <IconButton
            color="primary"
            className={styles.navigatorArrow}
            onClick={() => window.open("/uttarakhand/thingsToDo", "_blank")}
          >
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </IconButton> */}
        </article>
      </section>

      <PlanningNextTripBanner />

      <section className={styles.pilgrimsContainer}>
        <header className={styles.pilgrimsHeader}>
          Religious Places in <span>Uttarakhand</span>
        </header>
        <div className={styles.pilgrimsDescription}>
          Uttarakhand, whose historic temples grace misty alpine views, provides
          pilgrims with a heavenly haven amidst the majesty and peace of the
          natural world. Every step you take echoes with the sounds of
          dedication and reverence, from the sacred waters of the Ganges in
          Haridwar to the hallowed sanctuaries of Char Dham.
        </div>

        <div className={styles.pilgrimsCardList}>
          {pilgrimsCardContent.map((p, idx) => (
            <DestinationCardV2
              key={`${p.header}-${idx}`}
              className={styles.pilgrimsCard}
              onClick={(e) => {
                e.stopPropagation()
                dispatch(handleOpenVideoPlayerModal())
                dispatch(setVideoUrl(p.videoUrl))
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                }}
                key={p.videoUrl}
              >
                <ReactPlayer
                  url={p.videoUrl}
                  width="100%"
                  height="100%"
                  light={p.autoPlay}
                  playing={false}
                  playIcon={
                    <YoutubeIcon
                      style={{
                        width: "4rem",
                        boxSizing: "border-box",
                      }}
                    />
                  }
                />
              </div>
              {/* <DestinationCardV2.Footer>{p.header}</DestinationCardV2.Footer> */}
            </DestinationCardV2>
          ))}
          {/* <DestinationCardV2 className={styles.pilgrimsCard}>
            <DestinationCardV2.Image src={Rishikesh} />
            <DestinationCardV2.Footer>Rishikesh</DestinationCardV2.Footer>
          </DestinationCardV2>
          <DestinationCardV2 className={styles.pilgrimsCard}>
            <DestinationCardV2.Image src={Haridwar} />
            <DestinationCardV2.Footer>Haridwar</DestinationCardV2.Footer>
          </DestinationCardV2>
          <DestinationCardV2 className={styles.pilgrimsCard}>
            <DestinationCardV2.Image src={Chardham} />
            <DestinationCardV2.Footer>Chardham</DestinationCardV2.Footer>
          </DestinationCardV2> */}
        </div>
      </section>

      <section className={styles.bestPlacesToVisit}>
        <header onClick={() => window.open(`${path}/placesToStay`, "_blank")}>
          Best Places <span>To Visit</span>{" "}
        </header>
        <article className={styles.bestPlacestoVisitCardContainer}>
          {thingsTodoSource.map((item, idx) => (
            <DestinationCardV2
              className={styles.bestPlacestoVisitCard}
              key={`d-${idx}`}
            >
              <DestinationCardV2.Image src={item.src} />
              <DestinationCardV2.Footer>
                {item.name} <p>{item.subText}</p>
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
          {/* <IconButton
            color="primary"
            className={styles.navigatorArrow}
            onClick={() => window.open("/uttarakhand/placesToStay", "_blank")}
          >
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </IconButton> */}
        </article>
      </section>

      <section className={styles.keyfactsContainer}>
        <img src={KeyfactsBackground} style={{ filter: "grayscale(60%)" }} />
        <article className={styles.keyFactsArticle}>
          <header>
            Key <span>Facts?</span>
          </header>
          <div className={styles.keyFactsCardContainer}>
            {Keyfacts.map((fact, idx) => (
              <div className={styles.keyFactsCard} key={idx}>
                {fact.content}
              </div>
            ))}
          </div>
        </article>
      </section>

      <section className={styles.hillStationContainer}>
        <header
          className={styles.hillStationHeader}
          onClick={() =>
            window.open("/uttarakhand/festivalsAndEvents", "_blank")
          }
        >
          Festivals & <span>Events</span>{" "}
        </header>
        <div className={styles.hillStationDescription}>
          With its rich heritage and culture, Uttarakhand the perfect
          destination to celebrate festivals of colour and spirit.
        </div>

        <div className={styles.cardFlexContainer}>
          {festivalsAndEvents.map((festival, idx) => {
            return (
              <DestinationCardV2 className={styles.cardVariantOne} key={idx}>
                <DestinationCardV2.Image src={festival.src} />
                <DestinationCardV2.Footer className={styles.footerVariantTwo}>
                  {festival.name} {/*<p>Lorem ipsum dolor sit ame</p>*/}
                </DestinationCardV2.Footer>
              </DestinationCardV2>
            )
          })}

          {/* <IconButton
            color="primary"
            className={styles.navigatorArrow}
            onClick={() =>
              window.open("/uttarakhand/festivalsAndEvents", "_blank")
            }
          >
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </IconButton> */}
        </div>
      </section>

      {/* <section className={styles.tourPackages}>
        <header>Tour Packages</header>
        <div className={styles.tourPackagesContent}></div>
      </section> */}
    </>
  )
}

export default DestinationHome
